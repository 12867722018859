import { PageReport, pageReportsApi } from 'apis/flex/reporting';
import { defaultCrudHookBuilder, UseCrudProps } from 'hooks/useDefaultCrud';

const usePageReports = defaultCrudHookBuilder('pageReports', pageReportsApi, {
  staleTime: Infinity
});
export default usePageReports;
export type UseCurrentPageProps = {
  /**
   * Id for the page section where the report tiles live. Only useful if more than one report tile place exists on the page
   */
  section?: string;
  /**
   * Specify a custom 'location' key to load/save reports from/to this key
   */
  location?: string;
};
export const useCurrentPageReports = <T = PageReport[]>({
  filters,
  section = '',
  beforeSave = d => d,
  location: customLocation,
  ...props
}: UseCrudProps<PageReport, T> & UseCurrentPageProps = {}) => {
  return usePageReports({
    useFilter: true,
    filters: {
      ...filters,
      location: (customLocation || location.pathname) + '#' + section
    },
    beforeSave: d => ({
      ...beforeSave(d),
      location: (customLocation || location.pathname) + '#' + section
    }),
    ...props
  });
};
