import { useUser } from 'hooks/useUser';
import useTrainingCourses from './useTrainingCourses';
import usePublicUsers from 'components/app/users/hooks/usePublicUsers';
import useUserTraining from './useUserTraining';

export default () => {
  const { id: userId } = useUser();
  const { data: myTraining, isLoading: utLoading } = useUserTraining({
    filters: { userId },
    useFilter: !!userId
  });
  const myTrainingLookup = new Map(myTraining?.map(r => [r.courseId, r]));
  const { data: users } = usePublicUsers<{ label: string; value: number }[]>({
    useFilter: true,
    select: d =>
      d.map(u => ({ label: u.firstName + ' ' + u.surname, value: u.id }))
  });
  const { data, isLoading } = useTrainingCourses({ useFilter: true });
  return {
    courses: data,
    isLoading: isLoading || utLoading,
    myTrainingLookup,
    users,
    userId
  };
};
