import { EventPrefix } from 'apis/flex/notifications';
import { SelectorItem } from 'components/common/ItemSelector';
import React from 'react';
import {
  FiActivity,
  FiBookOpen,
  FiCheckSquare,
  FiClipboard,
  FiFileText,
  FiInbox,
  FiLifeBuoy,
  FiList,
  FiMail,
  FiTarget,
  FiUser,
  FiUserCheck,
  FiUserPlus,
  FiUsers
} from 'react-icons/fi';

export const version = process.env.REACT_APP_FLEXVERSION;
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export type DomainConfig<TData = any> = {
  sqlDb: string;
  sqlTable: string;
  foreignKey: string;
  icon?: React.ReactNode;
  home?: string | ((d: TData) => string);
  itemUrl?: string | ((d: TData) => string);
  format?: (d: TData) => SelectorItem;
  color?: string;
};
type Settings = {
  isFluid: boolean;
  isRTL: boolean;
  isDark: boolean;
  isDemo: boolean;
  navbarPosition: string;
  showBurgerMenu: boolean;
  currency: string;
  isNavbarVerticalCollapsed: boolean;
  navbarStyle: string;
  config: any;
  domains: Partial<{
    [key in EventPrefix]: DomainConfig;
  }>;
};
export const settings: Settings = {
  isFluid: false,
  isRTL: false,
  isDark: false,
  isDemo: true,
  navbarPosition: 'top',
  showBurgerMenu: true, // controls showing vertical nav on mobile
  currency: '£',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'transparent',
  config: {},
  domains: {
    ticket: {
      sqlDb: 'Support',
      sqlTable: 'Tickets',
      foreignKey: 'ticketId',
      icon: <FiLifeBuoy />,
      home: '/support/tickets',
      format: t => ({ label: 'Ticket #' + t.id, value: t.id })
    },
    applicant: {
      sqlDb: 'Recruitment',
      foreignKey: 'applicantId',
      sqlTable: 'AgentApplicants',
      icon: <FiUserPlus />,
      home: a => '/hr/recruitment/campaigns/' + a.campaignId,
      itemUrl: a =>
        '/hr/recruitment/campaigns/' + a.campaignId + '/applicants/' + a.id,
      format: d => ({ label: d.firstName + ' ' + d.surname, value: d.id })
    },
    'new-starter': {
      sqlDb: 'HR',
      foreignKey: 'employeeId',
      sqlTable: 'Employees',
      icon: <FiUserPlus />,
      home: '/hr/staff',
      format: d => ({ label: d.firstName + ' ' + d.surname, value: d.id })
    },
    'recruitment-campaign': {
      sqlDb: 'Recruitment',
      foreignKey: 'campaignId',
      sqlTable: 'Campaigns',
      icon: <FiUserPlus />,
      home: a => '/hr/recruitment/campaigns/' + a.campaignId,
      format: d => ({
        label: d.name,
        value: d.id,
        info: { createdBy: d.createdBy }
      })
    },
    form: {
      sqlDb: 'Forms',
      foreignKey: 'formId',
      sqlTable: 'CustomForms',
      icon: <FiCheckSquare />,
      home: '/forms',
      format: d => ({
        label: d.name,
        value: d.id,
        info: { createdBy: d.createdBy, createdDate: d.createdDate }
      })
    },
    // project: { icon: <FiList /> },
    user: {
      sqlDb: 'Users',
      foreignKey: 'userId',
      sqlTable: 'AppUsers',
      icon: <FiUser />,
      home: '/hr/staff',
      format: d => ({
        label: d.firstName + ' ' + d.surname,
        value: d.id,
        info: { jobTitle: d.jobTitle, email: d.email }
      })
    },
    employee: {
      sqlDb: 'HR',
      foreignKey: 'employeeId',
      sqlTable: 'Employees',
      icon: <FiUser />,
      home: '/hr/staff',
      format: d => ({
        label: d.firstName + ' ' + d.surname,
        value: d.id,
        info: { jobTitle: d.jobTitle, email: d.email }
      })
    },
    contract: {
      foreignKey: 'contractId',
      sqlDb: 'HR',
      sqlTable: 'Contracts',
      icon: <FiFileText />,
      home: '/hr/contracts',
      format: d => ({
        label: d.name,
        value: d.id,
        info: { createdDate: d.createdDate, createdBy: d.createdBy }
      })
    },
    department: {
      sqlDb: 'HR',
      foreignKey: 'departmentId',
      sqlTable: 'Departments',
      icon: <FiUsers />,
      home: '/hr/company/departments',
      format: d => ({
        label: d.name,
        value: d.id,
        info: {
          department: d.parentName,
          employees: d.employees + ' employees',
          subDepartments: d.departments + ' sub-departments'
        }
      })
    },
    'training-course': {
      sqlDb: 'HR',
      foreignKey: 'courseId',
      sqlTable: 'TrainingCourses',
      icon: <FiBookOpen />,
      home: '/hr/training/courses',
      format: d => ({
        label: d.name,
        value: d.id
      })
    },
    'user-training': {
      sqlDb: 'HR',
      sqlTable: 'UserTrainingCourses',
      foreignKey: 'userTrainingId',
      icon: <FiBookOpen />,
      home: '/hr/training/courses/sessions',
      format: d => ({
        label: 'Session #' + d.id,
        value: d.id,
        info: {
          enrolledDate: d.enrolledDate,
          user: d.userId
        }
      })
    },
    'applicant-stage': {
      sqlDb: 'Recruitment',
      foreignKey: 'applicantStageId',
      sqlTable: 'ApplicantStage',
      icon: <FiList />,
      home: '/hr/recruitment'
    },
    'employee-screening': {
      sqlDb: 'HR',
      foreignKey: 'screeningId',
      sqlTable: 'Screenings',
      icon: <FiUserCheck />,
      home: '/hr/staff/screening'
    },
    'mailer-template': {
      sqlDb: 'Notifications',
      foreignKey: 'notificationId',
      sqlTable: 'MailerTemplates',
      icon: <FiMail />,
      home: '/settings/notifications/templates',
      format: d => ({ label: d.name, value: d.id })
    },
    client: {
      sqlDb: 'Projects',
      sqlTable: 'Clients',
      foreignKey: 'clientId',
      icon: <FiUser />,
      home: '/pm/clients',
      format: d => ({ label: d.name, value: d.id })
    },
    project: {
      sqlDb: 'Projects',
      sqlTable: 'Projects',
      foreignKey: 'projectId',
      icon: <FiClipboard />,
      home: '/pm/projects',
      format: d => ({ label: d.name, value: d.id })
    },
    quote: {
      sqlDb: 'Projects',
      sqlTable: 'Quotes',
      foreignKey: 'quoteId',
      icon: <FiInbox />,
      home: '/pm/quotes',
      format: d => ({ label: d.reference + ' - ' + d.name, value: d.id })
    },
    'target-group': {
      sqlDb: 'Projects',
      sqlTable: 'TargetGroups',
      foreignKey: 'targetGroupId',
      icon: <FiTarget />,
      home: d => '/pm/projects/' + d.projectId + '/target-groups',
      format: d => ({ label: d.quoteRef + ' - ' + d.name, value: d.id })
    },
    'team-role': {
      sqlDb: 'Projects',
      sqlTable: 'TeamRoles',
      foreignKey: 'roleId',
      icon: <FiUsers />,
      home: '/pm/roles',
      format: d => ({ label: d.name, value: d.id })
    },
    'project-role': {
      sqlDb: 'Projects',
      sqlTable: 'ProjectRoles',
      foreignKey: 'projectRoleId',
      icon: <FiUsers />,
      home: d => '/pm/projects/' + d.projectId + '/roles',
      format: d => ({ label: d.roleName, value: d.id })
    }
  }
};

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
