import React from 'react';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { sqlValueFormatter } from 'components/common/advance-table-v2/AdvanceTableProvider';
import SettingsBox from 'components/common/SettingsBox';
import CustomTooltip from 'components/common/Tooltip';
import { ValidationRuleResults } from 'helpers/validation/validate';

export default ({ results }: { results: ValidationRuleResults }) => {
  return (
    <>
      {results?.map((ands, i) => (
        <SettingsBox
          key={i}
          variant={ands.some(r => r.result === true) && 'primary'}
          title={ands.some(r => r.result === true) ? 'Passed' : 'Not passed'}
          className="d-flex flex-column gap-3"
        >
          {ands.map((r, i) => (
            <div
              key={i}
              className="d-flex align-items-center justify-content-between w-100"
            >
              <div
                className="d-flex align-items-center flex-1"
                style={{ maxWidth: '90%' }}
              >
                <span className="me-2 fw-semi-bold fs--1">
                  {r.rule.question}:
                </span>
                <div
                  className="overflow-x-auto flex-1"
                  style={{ maxWidth: '90%' }}
                >
                  {r.value === undefined
                    ? 'N/A'
                    : sqlValueFormatter(
                        r.value?.score !== undefined ? 'score' : r.inputType
                      )(r.value)}
                </div>
              </div>
              <CustomTooltip
                content={
                  (r.result === true ? 'Passed' : 'Not passed') +
                  (': ' + r.description)
                }
              >
                <div>
                  <FontAwesomeIcon
                    className={classNames('ms-2', {
                      'text-primary': r.result === true
                    })}
                    icon={r.result === true ? faCheck : faTimes}
                  />
                </div>
              </CustomTooltip>
            </div>
          ))}
        </SettingsBox>
      ))}
    </>
  );
};
