import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { createPortal } from 'react-dom';

const CardDropdown = ({
  btnRevealClass,
  drop,
  children,
  icon = 'ellipsis-h',
  className = '',
  buttonClass = ''
}) => {
  return (
    <Dropdown
      className={classNames(className, 'font-sans-serif btn-reveal-trigger')}
      align={'end'}
      drop={drop}
    >
      <Dropdown.Toggle
        variant="link"
        size="sm"
        data-boundary="viewport"
        className={classNames('text-600', buttonClass, {
          [btnRevealClass]: btnRevealClass,
          'btn-reveal': !btnRevealClass
        })}
      >
        <FontAwesomeIcon icon={icon} className="fs--2" />
      </Dropdown.Toggle>
      {createPortal(
        <Dropdown.Menu
          popperConfig={{ modifiers: { flip: true } }}
          className="border py-0"
          style={{ zIndex: 1015 }}
        >
          {children}
          {!children && (
            <div className="py-2">
              <Dropdown.Item>View</Dropdown.Item>
              <Dropdown.Item>Export</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item className="text-danger">Remove</Dropdown.Item>
            </div>
          )}
        </Dropdown.Menu>,
        document.body
      )}
    </Dropdown>
  );
};

CardDropdown.propTypes = {
  btnRevealClass: PropTypes.string,
  drop: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string,
  className: PropTypes.string,
  buttonClass: PropTypes.string
};

export default CardDropdown;
