import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CardProps, Nav } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import blankUser from 'assets/img/team/avatar.png';
import { useNavigate } from 'react-router-dom';
import Flex from 'components/common/Flex';
import { addDays, format, isSameDay, startOfISOWeek } from 'date-fns';
import { weekdaysIn } from 'helpers/dates';
import WeeklyChart from 'components/common/widgets/WeeklyChart';
import MiniDonut from 'components/common/widgets/MiniDonut';
import { FiUsers } from 'react-icons/fi';
import CustomFormResponseTable, {
  CustomFormResponseTableData
} from 'components/common/customForms/Reporting/CustomFormResponseTable';
import useApplicantActions from './hooks/useApplicantActions';
import useApplicantGroups from './hooks/useApplicantGroups';
import is from 'is_js';
import SimpleLine from 'components/common/widgets/SimpleLine';
import { groupBy, meanBy } from 'lodash';
import { notificationEvents } from 'components/notification/Notification';
import StarRating from 'components/common/StarRating';
import UserIcons from 'components/app/users/widgets/UserIcons';
import { useUser } from 'hooks/useUser';
import useCampaign from '../campaigns/hooks/useCampaign';
import useCampaignApplicants from './hooks/useCampaignApplicants';
import UserIcon from 'components/app/users/widgets/UserIcon';
import {
  faCheckCircle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import TooltipBadge from 'components/common/TooltipBadge';
import { Applicant, ApplicantResponse } from 'apis/flex/recruitment';
import { useNewDomainNotifications } from 'components/notification/useNotifications';
import { Column } from 'components/common/advance-table-v2/AdvanceTableProvider';
import ActionListener from 'components/notification/ActionListener';
import LinkClicks from 'components/common/widgets/LinkClicks';

const ApplicantsTable = ({ campaignId, filter = () => true, title }) => {
  const nav = useNavigate();
  const { actions, handleEdit, ActionModal, EditModal, handleScore } =
    useApplicantActions(campaignId);
  const {
    data: applicants,
    isLoading: applicantsLoading,
    error
  } = useCampaignApplicants({
    campaignId
  });
  const { data: campaign, isLoading } = useCampaign({
    id: campaignId,
    staleTime: Infinity
  });
  const getApplicant = useCallback<
    (formResponse: CustomFormResponseTableData) => ApplicantResponse
  >(
    formResponse => {
      return (applicants?.find(a => a.response?.id === formResponse.id) ||
        {}) as ApplicantResponse;
    },
    [applicants]
  );
  const getApplicantId = (r: CustomFormResponseTableData) =>
    getApplicant(r).applicant?.id;
  const { id: userId } = useUser();
  const applicantAccessor = useCallback(
    (accessor: (a: Applicant) => any) =>
      (data: CustomFormResponseTableData) => {
        const a = getApplicant(data).applicant;
        if (!a) return '';
        return accessor(a);
      },
    [getApplicant]
  );
  const cols = useMemo<Column<CustomFormResponseTableData>[]>(
    () => [
      {
        id: 'name',
        accessorFn: applicantAccessor(a => a.firstName + ' ' + a.surname),
        formatter: getValue => (
          <Flex alignItems="center">
            <Avatar
              src={blankUser}
              className={`status-offline me-2`}
              mediaClass={'border border-3 rounded-circle border-200 bg-100'}
            />
            <span className="me-4">{getValue()}</span>
          </Flex>
        )
      },
      {
        id: 'score',
        type: 'number',
        accessorFn: applicantAccessor(a => meanBy(a.scores, s => s.score)),
        formatter: (get, d) => {
          if (!getApplicant(d)?.applicant) return null;
          const ids = getApplicant(d)?.applicant?.scores?.map(s => s.scoredBy);
          return (
            <Flex alignItems={'center'}>
              {ids?.length && <UserIcons className="me-2" ids={ids} />}
              <StarRating
                color="primary"
                fractions={4}
                stop={5}
                rating={get()}
                readonly
              />
            </Flex>
          );
        }
      },
      {
        id: 'myScore',
        type: 'number',
        accessorFn: applicantAccessor(
          a => a?.scores?.find(s => s.scoredBy === userId)?.score
        ),
        formatter: (get, d) => (
          <div
            onClick={e => {
              // e.preventDefault();
              e.stopPropagation();
            }}
          >
            <StarRating
              fractions={1}
              stop={5}
              rating={get()}
              handleChange={score => handleScore([getApplicantId(d)], score)}
            />
          </div>
        )
      },
      { id: 'email', accessorFn: applicantAccessor(a => a.email) },
      { id: 'submittedDate', type: 'datetime' },
      {
        id: 'acceptReject',
        header: 'Outcome',
        accessorFn: applicantAccessor(a => a.acceptReject),
        formatter: (get, d) => {
          return (
            get() && (
              <TooltipBadge
                className="fs-3"
                icon={get() === 'accepted' ? faCheckCircle : faTimesCircle}
                color={get() === 'accepted' ? 'success' : 'danger'}
                tooltip={
                  <UserIcon
                    showName
                    hideJobTitle
                    id={getApplicant(d)?.applicant.acceptRejectBy}
                  />
                }
              />
            )
          );
        }
      }
    ],
    [applicantAccessor]
  );
  const { data: notifications } = useNewDomainNotifications({
    domain: 'applicant'
  });
  const groups = useApplicantGroups({ campaignId });
  return (
    <CustomFormResponseTable
      tabs={
        !applicants
          ? [{ label: 'Loading...', filter: () => false }]
          : groups?.map(g => ({
              label: g.title,
              filter: d => {
                return (
                  !!getApplicant(d)?.applicant &&
                  g.filter(getApplicant(d)?.applicant)
                );
              }
            }))
      }
      error={error}
      allQuestions
      isNew={r => {
        return notifications?.some(n => n.itemId == getApplicantId(r.original));
      }}
      onDataLoaded={useCallback(
        (responses: CustomFormResponseTableData[]) => {
          const filtered = responses.filter(
            r => !!applicants.find(a => a.response.id === r.id)
          );
          return filtered;
        },
        [applicants, filter]
      )}
      isLoading={isLoading || applicantsLoading}
      formId={!!applicants && campaign?.formId}
      columns={cols}
      // title={title}
      emptyPlaceholder={
        'There are no ' +
        (title ? title.toLowerCase() + ' ' : '') +
        'applicants to show'
      }
      // sqlTables={['AgentApplicants']}
      onEditClick={useCallback(
        rows => handleEdit(rows.map(r => getApplicantId(r.original))),
        [getApplicantId]
      )}
      onRowClick={useCallback(
        r => {
          nav('applicants/' + getApplicant(r.original).applicant.id);
        },
        [getApplicant]
      )}
      actions={useMemo(
        () =>
          actions.map(a => ({
            ...a,
            name: a.label,
            onClick: row => {
              a.fn([getApplicantId(row.original)]);
            },
            show: row =>
              typeof a.show === 'boolean'
                ? !!a.show
                : a.show === undefined
                ? true
                : a.show([getApplicantId(row)])
          })),
        [actions, getApplicantId]
      )}
      initialSort={[{ id: 'submittedDate', desc: true }]}
      bulkActions={useMemo(
        () =>
          actions.map(a => ({
            ...a,
            name: a.label,
            actionFn: async rows => {
              a.fn(rows.map(r => getApplicantId(r.original)));
            },
            show: rows =>
              typeof a.show === 'boolean'
                ? !!a.show
                : a.show === undefined
                ? true
                : a.show(rows.map(r => getApplicantId(r.original)))
          })),
        [actions, getApplicantId]
      )}
    >
      <ActionModal />
      <EditModal />
      <ActionListener seen eventName={'applicant-added'} />
    </CustomFormResponseTable>
  );
};
ApplicantsTable.propTypes = {
  filter: PropTypes.func,
  title: PropTypes.string,
  campaignId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
const NavItem = ({ item, count }) => {
  return (
    <Nav.Item as="li">
      <Nav.Link
        className="mb-0 py-3 cursor-pointer h-100"
        eventKey={item.title.toLowerCase()}
      >
        {item.title} {count ? '(' + count + ')' : ''}
      </Nav.Link>
    </Nav.Item>
  );
};
NavItem.propTypes = {
  item: PropTypes.object,
  count: PropTypes.number
};
export const WeeklyApplicants = ({ campaignId, ...cardProps }) => {
  const { data = [] } = useCampaignApplicants({ campaignId });
  const startOfWeek = startOfISOWeek(new Date());
  const startOfPrevWeek = startOfISOWeek(addDays(new Date(), -7));
  const getWeekdayData = date =>
    weekdaysIn(date).map(
      wd =>
        data.filter(d => isSameDay(new Date(d.response.submittedDate), wd))
          .length
    );
  const current = getWeekdayData(startOfWeek);
  const prev = getWeekdayData(startOfPrevWeek);
  return (
    <WeeklyChart
      title="This week's applications"
      // description={
      //   'Showing data for week commencing Monday ' +
      //   format(startOfLastWeek, 'dd/MM/yyyy')
      // }
      data={current}
      previous={prev}
      {...cardProps}
    />
  );
};
WeeklyApplicants.propTypes = {
  cardProps: PropTypes.object,
  campaignId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
export const TotalApplicants = ({ campaignId, ...cardProps }) => {
  const { data = [] } = useCampaignApplicants({ campaignId });
  const lineData = groupBy(data, d =>
    format(new Date(d.response.submittedDate), 'dd/MM/yyyy')
  );
  return (
    <SimpleLine
      title="Total applicants"
      data={Object.keys(lineData).map(dte => lineData[dte].length)}
      total={data.length}
      prevTotal={
        data.filter(
          d => new Date(d.response.submittedDate) < addDays(new Date(), -14)
        ).length
      }
      {...cardProps}
    />
  );
};
export const CampaignClicks = ({
  campaignId,
  ...cardProps
}: { campaignId: number } & CardProps) => {
  const { data: campaign } = useCampaign({
    id: campaignId,
    staleTime: Infinity
  });
  return <LinkClicks linkId={campaign?.inviteLinkId} {...cardProps} />;
};
TotalApplicants.propTypes = WeeklyApplicants.propTypes;
export const ApplicantOutcomes = ({ campaignId, ...cardProps }) => {
  const { data = [] } = useCampaignApplicants({
    campaignId,
    select: d =>
      d.filter(a => is.inLastMonth(new Date(a.response.submittedDate)))
  });
  return (
    <MiniDonut
      title="Recent Outcomes"
      description="From applications submitted within the last month"
      icon={<FiUsers />}
      data={[
        {
          name: 'Accepted',
          color: 'success',
          value: data.filter(d => d.applicant.acceptReject === 'accepted')
            .length
        },
        {
          name: 'Rejected',
          color: 'danger',
          value: data.filter(d => d.applicant.acceptReject === 'rejected')
            .length
        },
        {
          name: 'No outcome',
          color: 'secondary',
          value: data.filter(d => !d.applicant.acceptReject).length
        }
      ]}
      {...cardProps}
    />
  );
};
ApplicantOutcomes.propTypes = WeeklyApplicants.propTypes;
export const ApplicantsCard = ({ campaignId }: { campaignId: number }) => {
  // const [navItems, setNavItems] = useState([]);
  // const { data: applicants = [] } = useCampaignApplicants({
  //   campaignId
  // });
  // const groups = useApplicantGroups({ campaignId });
  // useEffect(() => {
  //   setNavItems(groups);
  // }, [groups]);
  // const [active, setActive] = useState<any>();
  // useEffect(() => {
  //   if (navItems[0] && !active) {
  //     setActive(navItems[0]);
  //   }
  // }, [navItems]);
  return <ApplicantsTable campaignId={campaignId} />;
};
// export default AllApplicants;
