import { UserTrainingCourse } from 'apis/flex/hr';
import { useWatch } from 'react-hook-form';

export default ({ index }) => {
  const userResource = useWatch<
    UserTrainingCourse,
    [
      `resources.${number}.finishedDate`,
      `resources.${number}.approvedDate`,
      `resources.${number}.failedDate`,
      `resources.${number}.startedDate`
    ]
  >({
    name: [
      `resources.${index}.finishedDate`,
      `resources.${index}.approvedDate`,
      `resources.${index}.failedDate`,
      `resources.${index}.startedDate`
    ]
  });
  const isStarted = !!userResource[3];
  const isFinished = !!userResource[0];
  const isComplete = !!userResource[1];
  const isFailed = !!userResource[2];
  const isPending = !isComplete && !isFailed && isFinished;

  return { isFinished, isComplete, isFailed, isPending, isStarted };
};
