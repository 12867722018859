import { UserTrainingCourse, trainingApi } from 'apis/flex/hr';
import useDefaultCrud, { UseCrudProps } from 'hooks/useDefaultCrud';

export default <TSelected = UserTrainingCourse[]>(
  props: UseCrudProps<UserTrainingCourse, TSelected> = {}
) => {
  return useDefaultCrud<UserTrainingCourse, TSelected>(
    'UserTrainingCourses',
    trainingApi.userCourses,
    { ...props, staleTime: props.staleTime || 1000 * 60 * 10 }
  );
};
