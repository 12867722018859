import React from 'react';
import Skeleton from 'react-loading-skeleton';
import ApplicantIconGroup from '../ApplicantGroup';
import PropTypes from 'prop-types';
import useApplicants from '../hooks/useApplicants';
const SelectedApplicants = ({ ids }: { ids: number[] }) => {
  const { data: applicants, isLoading: applicantsLoading } = useApplicants({
    filters: { id: ids },
    select: d =>
      d
        .filter(ap => ids.some(a => a == ap.applicant.id))
        .map(a => ({
          name: a.applicant.firstName + ' ' + a.applicant.surname,
          submittedDate: a.response.submittedDate,
          email: a.applicant.email
        }))
  });
  return applicantsLoading ? (
    <Skeleton height={'2rem'} />
  ) : (
    <ApplicantIconGroup data={applicants} />
  );
};
SelectedApplicants.propTypes = {
  ids: PropTypes.array
};
export default SelectedApplicants;
