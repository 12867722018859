import React, { useEffect } from 'react';
import CustomFormWizard, {
  CustomFormWizardProps
} from 'components/common/customForms/Wizard/CustomFormWizard';
import PropTypes from 'prop-types';
import useCustomForm from './hooks.js/useCustomForm';
import { Form } from 'apis/flex/customForms';
import { OnWizardSubmit } from 'components/wizard/FormWizard';
import { Card, Col, Row } from 'react-bootstrap';
import { format, formatDuration, intervalToDuration } from 'date-fns';
import Lottie from 'lottie-react';
import comingSoon from 'assets/img/animated-icons/coming-soon.json';
import locked from 'assets/img/animated-icons/locked.json';
import usePublicCampaign from 'components/app/recruitment/campaigns/hooks/usePublicCampaign';
import Error500 from 'components/errors/Error500';
import { useQuery } from '@tanstack/react-query';
import { applicantsAPI } from 'apis/flex/recruitment';

const StartCountdown = ({
  startDate,
  checkMs = 1000,
  format = ['days', 'hours', 'minutes', 'seconds']
}) => {
  const [duration, setDuration] = React.useState('');
  const [interval, setIntervalState] = React.useState(null);
  useEffect(() => {
    setIntervalState(
      setInterval(() => {
        setDuration(
          formatDuration(
            intervalToDuration({
              start: new Date(),
              end: new Date(startDate)
            }),
            {
              format
            }
          )
        );
      }, checkMs)
    );
    return () => clearInterval(interval);
  }, [format, startDate, checkMs]);
  return <h4>Ready in {duration}</h4>;
};
export const FormClosed = () => {
  return (
    <Card>
      <Card.Body className="overflow-hidden p-lg-6">
        <Row className="align-items-center justify-content-between">
          <Col lg={6}>
            <Lottie animationData={locked} />
          </Col>

          <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-left">
            <h4>Closed</h4>
            <p>
              It looks like this form is now closed. Thank you for your interest
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
/**
 *Responsible only for loading a custom form and handling submit functionality
 **/
const CustomFormClient = ({
  formId,
  onSubmitted = ({ done }) => done(),
  onScreenout = ({ goToNext }) => goToNext(),
  campaignId = null,
  campaignType = null,
  applicantStageId = null,
  formMutator = (form: Form) => form,
  isLoading: parentLoading,
  recaptcha,
  ...rest
}: Omit<CustomFormWizardProps, 'onSubmit' | 'form'> & {
  onSubmitted?: OnWizardSubmit;
  formId: number;
  formMutator?: (form: Form) => Form;
  campaignId?: string;
  campaignType?: string;
  applicantStageId?: number;
}) => {
  const {
    handleSubmit: _handleSubmit,
    form,
    isLoading
  } = useCustomForm({
    formId: formId,
    enabled: !!formId,
    meta: { campaignId, campaignType, applicantStageId }
  });
  const {
    data: campaign,
    isLoading: campaignLoading,
    error
  } = usePublicCampaign({
    id: campaignId,
    staleTime: Infinity
  });
  const {
    data: stage,
    error: stageError,
    isLoading: stageLoading
  } = useQuery<any, Error>({
    queryKey: ['ApplicantStage', applicantStageId],
    enabled: !!applicantStageId,
    select: d => d[0],
    queryFn: async () => {
      if (!applicantStageId) return;
      return applicantsAPI.stage.getPublic({ id: applicantStageId });
    }
  });
  if (
    stage?.completedDate ||
    (stage?.dueDate && new Date(stage?.dueDate) < new Date())
  ) {
    return <FormClosed />;
  }
  return (
    <>
      {(!!campaignId && !!error) || (!!applicantStageId && !!stageError) ? (
        <Error500
          error={{
            ...error,
            ...stageError,
            message: 'Could not load campaign'
          }}
        />
      ) : (
        <>
          {!!campaign?.startDate &&
          new Date() < new Date(campaign?.startDate) ? (
            <Card>
              <Card.Body className="overflow-hidden p-lg-6">
                <Row className="align-items-center justify-content-between">
                  <Col lg={6}>
                    <Lottie animationData={comingSoon} />
                  </Col>
                  <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-left">
                    <StartCountdown
                      startDate={campaign?.startDate}
                      format={['days', 'hours']}
                    />
                    <p className="lead">
                      This form isn't available until{' '}
                      {format(new Date(campaign?.startDate), 'dd/MM/yyyy')}.
                      Please check back later
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          ) : (!!campaign?.endDate &&
              new Date() > new Date(campaign?.endDate)) ||
            (!!campaign && !campaign.isActive) ||
            campaign?.maxApplicantsReached ? (
            <FormClosed />
          ) : (
            <CustomFormWizard
              form={formMutator(form)}
              continueOnScreenout={!form?.exitScreenouts}
              isFormLoading={
                isLoading ||
                parentLoading ||
                (campaignLoading && !!campaignId) ||
                (stageLoading && !!applicantStageId)
              }
              companyId={form?.companyId}
              // storeId={'CustomForm' + formId}
              recaptcha={
                recaptcha === false
                  ? false
                  : recaptcha || (form && !form?.requireLogin)
              }
              onSubmit={props => {
                _handleSubmit(
                  props.data,
                  props.screenoutIds.map(id => Number(id)),
                  resp => {
                    props.setAuthItemId(resp.id, () => {
                      onSubmitted({
                        ...props,
                        data: resp,
                        setAuthItemId: id => props.setAuthItemId(id)
                      });
                    });
                  },
                  err => {
                    console.log(err, 'error heard in customformclient');
                    props.err(err);
                  }
                );
              }}
              onScreenout={onScreenout}
              {...rest}
            />
          )}
        </>
      )}
    </>
  );
};
CustomFormClient.propTypes = {
  form: PropTypes.object,
  isLoading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleScreenout: PropTypes.func
};
export default CustomFormClient;
