import { useQuery } from '@tanstack/react-query';
import { RecruitmentStage, stagesAPI } from 'apis/flex/recruitment';
import { ApiFilters } from 'apis/types';

export default <TSelected = RecruitmentStage[]>({
  campaignId,
  enabled = true,
  select,
  filters
}: {
  campaignId?: number;
  enabled?: boolean;
  filters?: ApiFilters;
  select?: (data: RecruitmentStage[]) => TSelected;
}) => {
  return useQuery<RecruitmentStage[], Error, TSelected>({
    enabled: !!campaignId && !!enabled,
    queryFn: () => stagesAPI.get(campaignId, filters),
    select,
    queryKey: ['recruitmentStages', campaignId, filters]
  });
};
