import AdvanceTable from 'components/common/advance-table-v2/AdvanceTable';
import AdvanceTableProvider, {
  AdvanceTableProviderProps,
  Column
} from 'components/common/advance-table-v2/AdvanceTableProvider';
import React from 'react';
import { UseDefaultCrudReturn } from 'hooks/useDefaultCrud';
import AdvanceEditor from 'components/common/advance-table-v2/AdvanceEditor';
import { domainConfigs } from 'components/notification/config';
import { DomainConfig } from 'config';
import { EventPrefix } from 'apis/flex/notifications';
import { useNavigate } from 'react-router-dom';
import { faCopy, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

export default <TData extends { id?: number } = any>({
  domain,
  crudHook,
  editFields,
  ...props
}: {
  editFields?: (keyof TData)[];
  domain: EventPrefix;
  columns: Column<TData>[];
  crudHook: Pick<
    UseDefaultCrudReturn<TData>,
    | 'data'
    | 'bulkUpdate'
    | 'isBulkUpdating'
    | 'isLoading'
    | 'cloneAsync'
    | 'isCloning'
    | 'removeAsync'
    | 'bulkRemoveAsync'
  >;
} & Partial<AdvanceTableProviderProps>) => {
  const config = domainConfigs[domain] as DomainConfig<TData>;
  const nav = useNavigate();
  return (
    <AdvanceTableProvider
      autoFocus
      isLoading={crudHook.isLoading}
      data={crudHook.data}
      sqlDb={config?.sqlDb}
      sqlTables={[config.sqlTable]}
      domain={domain}
      columns={['id']}
      onRowClick={r => nav(r.original.id.toString())}
      onNewClick={() => nav('new')}
      actions={[
        {
          name: 'Edit',
          onClick: row => nav(row.original.id.toString()),
          icon: faEdit
        },
        {
          name: 'Duplicate',
          onClick: row => crudHook.cloneAsync(row.original.id),
          icon: faCopy
        },
        {
          name: 'Delete',
          variant: 'danger',
          confirm: 'Delete this record?',
          onClick: row => crudHook.removeAsync(row.original.id),
          icon: faTrash
        }
      ]}
      bulkActions={[
        {
          name: 'Delete',
          confirm: rows => 'Delete all ' + rows.length + ' records?',
          actionFn: rows =>
            crudHook.bulkRemoveAsync(rows.map(r => r.original.id)),
          icon: faTrash
        }
      ]}
      {...props}
    >
      <AdvanceTable />
      {editFields && (
        <AdvanceEditor
          data={crudHook.data}
          domain={domain as EventPrefix}
          crudHook={crudHook}
          fields={editFields}
        />
      )}
    </AdvanceTableProvider>
  );
};
