import React from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { transitions } from 'helpers/animations';
export default ({ children, direction, motionKey }) => {
  const variants = {
    exit: p =>
      p === 'backward' ? { x: '150%', opacity: 0 } : { x: '-150%', opacity: 0 }
  };
  return (
    <AnimatePresence mode="popLayout" initial={false} custom={direction}>
      <motion.div
        custom={direction}
        key={motionKey}
        id={motionKey}
        // layoutScroll
        initial={{
          x: direction === 'backward' ? '-150%' : '150%',
          opacity: 0
        }}
        animate={{ x: 0, opacity: 1 }}
        variants={variants}
        exit={'exit'}
        // className="position-relative w-100"
        // style={{ transition: 'height 0.5s' }}
        transition={{ ...transitions.lightBounce, damping: 17 }}
        // transition={{ duration: 2 }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};
