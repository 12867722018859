import { useQuery } from '@tanstack/react-query';
import {
  Applicant,
  ApplicantResponse,
  applicantsAPI
} from 'apis/flex/recruitment';
import { ApiFilters } from 'apis/types';
import useFormResponses from 'components/common/customForms/hooks.js/useFormResponses';
import { uniq } from 'lodash';
import { useMemo } from 'react';

export default <T = ApplicantResponse[]>({
  select = d => d as T,
  filters,
  id,
  enabled = true,
  staleTime = 1000 * 30,
  ...rest
}: {
  id?: number;
  select?: (data: ApplicantResponse[]) => T;
  filters?: ApiFilters;
  staleTime?: number;
  enabled?: boolean;
} = {}) => {
  const { data, isLoading, error } = useQuery<any, Error, Applicant[]>({
    queryKey: ['Applicants', id, filters, staleTime],
    queryFn: () =>
      applicantsAPI.get({
        filters,
        id
      }),
    enabled,
    staleTime,
    ...rest
  });
  const formIds = uniq(data?.map((d: Applicant) => d.applicationFormId));
  const {
    data: responses,
    isLoading: frLoading,
    error: frError
  } = useFormResponses({
    filters: { formId: formIds },
    useFilter: true,
    staleTime,
    enabled: !!formIds.length
  });
  const formResponseLookup = useMemo(
    () => new Map(responses?.map((d: any) => [d.id, d])),
    [responses]
  );
  const dataWithResponse: T = useMemo(
    () =>
      select(
        data
          ?.map((d: Applicant) => {
            return {
              applicant: d,
              response: formResponseLookup.get(d.responseId)
            };
          })
          .filter(d => d.response) || []
      ),
    [data, formResponseLookup]
  );
  return {
    data: dataWithResponse,
    isLoading: isLoading || frLoading,
    error: error || frError
  };
};
