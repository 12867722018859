import { getColor, rgbaColor } from 'helpers/utils';
import {
  aggregateSeriesData,
  nextColor,
  Series,
  XAxis,
  YAxis
} from '../helpers';
import { EChartsOption, SeriesOption } from 'echarts';
const seriesCreator =
  (type: string, xAxes: XAxis[], simple: boolean, stacked?: boolean) =>
  (ser, i): SeriesOption => {
    const color = getColor(ser.color || nextColor(i));
    return {
      connectNulls: true,
      smooth: true,
      name: ser.name || '',
      type: type as any,
      radius: ['60%', '90%'],
      label: type === 'pie' && {
        show: false,
        position: 'center' as any
      },
      labelLine: {
        show: false
      },
      stack: ser.stack || stacked ? 'Total' : undefined,
      data: (ser.data || []).map((d, xi) => ({
        value: d,
        name: xAxes?.[0]?.data[xi] || ser.name
      })),
      //   color:
      //     type === 'pie' && ser.data.map((_, i) => getColor(nextColor(i))),
      lineStyle: { color: color },
      itemStyle: {
        color: color,
        borderColor: type === 'pie' ? '#fff' : color,
        borderRadius: ser.stack || stacked ? undefined : simple ? 3 : 10,
        borderWidth: type !== 'bar' && 2
      },
      tooltip: {
        valueFormatter: ser.valueFormatter
      },
      xAxisId: ser.axisId,
      areaStyle: {
        color: {
          type: 'linear' as any,
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(color, 0.2)
            },
            {
              offset: 1,
              color: rgbaColor(color, 0.01)
            }
          ]
        }
      },
      showSymbol: false,
      emphasis:
        type === 'pie'
          ? {
              label: {
                show: true,
                fontSize: 14 as any,
                fontWeight: 'bold' as any
              }
            }
          : {
              scale: true,
              focus: 'series'
            }
    } as SeriesOption;
  };
export const getChartOptions = (
  type: 'line' | 'pie' | 'bar',
  stacked?: boolean
) => {
  const shouldShowAxes = type === 'bar' || type === 'line';
  return (
    simple: boolean,
    xAxes: XAxis[],
    yAxes: YAxis[],
    series: Series[]
  ) => {
    const minDataPoint = aggregateSeriesData(series, 'min');
    const maxDataPoint = aggregateSeriesData(series, 'max');
    // console.log('min datapoint', minDataPoint, series);
    const seriesDef = series.map(seriesCreator(type, xAxes, simple, stacked));
    if (type === 'pie') {
      //remove all but first def
      seriesDef.splice(1, seriesDef.length - 1);
      if (seriesDef[0]) {
        //convert series split into data split
        seriesDef[0].data = series.map(s => ({
          //sum up any axis values, which are irrelevant in a pie
          value: s.data
            .filter(d => !isNaN(d))
            .reduce((a, b) => a + Number(b), 0),
          name: s.name
        }));
        seriesDef[0].name = null;
      }
    }
    // console.log(
    //   'creating chart options',
    //   type,
    //   xAxes,
    //   yAxes,
    //   series,
    //   seriesDef
    // );
    const option: EChartsOption = {
      toolbox: !simple && {
        feature: {
          saveAsImage: {
            show: true
          },
          //   dataZoom: {
          //     show: true
          //   },
          //   dataView: {
          //     show: true
          //   },
          magicType: {
            show: true
          },
          reset: {
            show: true
          }
        }
      },
      legend: {
        show: false,
        data: series.map(s => s.name)
      },
      visualMap: {
        show: false,
        min: minDataPoint,
        max: maxDataPoint,
        inRange: {
          colorLightness: [0.8, 0.2]
        }
      },
      tooltip: {
        trigger: type === 'pie' ? 'item' : 'axis',
        padding: [7, 10],
        backgroundColor: getColor('gray-100'),
        borderColor: getColor('gray-100'),
        borderWidth: 1
      },
      xAxis: xAxes?.map((axis, i) => {
        return {
          id: Number(axis.id || i).toString(),
          type: 'category',
          data: axis.data,
          boundaryGap: type === 'bar',
          show: shouldShowAxes,
          axisPointer: {
            lineStyle: {
              color: getColor('gray-300')
            }
          },
          axisLine: {
            lineStyle: {
              color: getColor('gray-300'),
              type: 'solid'
            },
            show: !simple
          },
          axisTick: { show: false },
          axisLabel: {
            color: getColor('gray-400'),
            formatter: axis.formatter,
            margin: 15,
            show: !simple
          },
          splitLine: {
            show: !simple && shouldShowAxes,
            lineStyle: {
              color: getColor('gray-300'),
              type: 'dashed'
            }
          }
        };
      }),
      yAxis: yAxes.map((axis, i) => {
        return {
          id: i.toString(),
          type: 'value',
          show: shouldShowAxes,
          max: axis.max,
          min: axis.min,
          splitLine: shouldShowAxes && {
            lineStyle: {
              color: getColor('gray-300')
            },
            show: !simple
          },
          axisLabel: {
            color: getColor('gray-400'),
            margin: 15,
            show: !simple,
            formatter: axis.formatter
          }
        };
      }),
      series: seriesDef,
      grid:
        type !== 'pie'
          ? {
              right: 7,
              left: 0,
              bottom: 5,
              top: 10,
              containLabel: true
            }
          : {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              containLabel: true
            }
    };
    return option;
  };
};
