import React from 'react';
import { useMemo } from 'react';
import AdminEditorModal from 'components/common/admin-table-v2/AdminEditorModal';
import { customFormsResponseApi } from 'apis/flex/customForms';
import CustomWizardInput from 'components/common/customForms/Wizard/CustomWizardInput';
import PropTypes from 'prop-types';
import useCustomForm from 'components/common/customForms/hooks.js/useCustomForm';
import useCampaignApplicants from '../hooks/useCampaignApplicants';

const ApplicantEditModal = ({
  show,
  setShow,
  ids = [],
  data = {},
  campaignId,
  formId
}) => {
  const { form } = useCustomForm({
    formId
  });
  const fields = useMemo(
    () =>
      (form?.sections || [])
        .map(section =>
          section.questions.map(q => ({
            id: q.id.toString(),
            title: q.questionText,
            editor: props => {
              return <CustomWizardInput inputProps={props} question={q} />;
            },
            colProps: { xs: 12, lg: 12 }
          }))
        )
        .flat(2),
    [form]
  );
  const { data: applicants } = useCampaignApplicants({ campaignId });
  return (
    <AdminEditorModal
      fields={fields}
      show={show}
      setShow={setShow}
      sqlTables={[]}
      queryKeys={[['Applicants', ...ids], ['CustomFormResponses']]}
      mutationFn={data =>
        customFormsResponseApi.updateBulk(
          {
            formId: form?.id,
            responseIds: ids.map(
              id => applicants.find(a => a.applicant.id == id).response.id
            )
          },
          data
        )
      }
      data={data}
    />
  );
};
ApplicantEditModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  ids: PropTypes.array,
  data: PropTypes.object,
  campaignId: PropTypes.number,
  formId: PropTypes.number
};
export default ApplicantEditModal;
