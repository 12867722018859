import { Screening, screeningApi } from 'apis/flex/hr';
import useDefaultCrud, { UseCrudProps } from 'hooks/useDefaultCrud';

export default <TSelected = Screening[]>(
  props?: UseCrudProps<Screening, TSelected>
) => {
  return useDefaultCrud('Prescreenings', screeningApi, {
    ...props,
    staleTime: 1000 * 60 * 10
  });
};
