import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState, useTransition } from 'react';
import { Col, Form, Row, Button, Spinner, Modal } from 'react-bootstrap';
import AdvanceTableSearchBox from './AdvanceTableSearchBox';
import { camelToSentence, downloadCSV } from 'helpers/utils';
import {
  BulkAction,
  ProcessedCell,
  useAdvanceTable
} from './AdvanceTableProvider';
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AdvanceTableFilters } from './AdvanceTableFilters';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from '../FalconCardHeader';
import { format } from 'date-fns';
import AdminEditorModal from '../admin-table-v2/AdminEditorModal';
import Flex from '../Flex';
import { ModalConfirm, ResponsiveModal } from '../Modals';
import { FormProvider, useForm } from 'react-hook-form';
import WizardInput from 'components/wizard/WizardInput';
import { Row as RowType } from '@tanstack/react-table';

const BulkEditModal = ({ show, setShow }) => {
  return <AdminEditorModal show={show} setShow={setShow} />;
};
BulkEditModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func
};
const ExportModal = ({
  rows,
  exportTitle,
  show,
  setShow,
  exportAllData
}: {
  rows: RowType<any>[];
  exportTitle: string;
  show: boolean;
  setShow: (show: boolean) => void;
  exportAllData?: boolean;
}) => {
  const { getAllFlatColumns } = useAdvanceTable();
  const getAllHeaders = () => {
    return getAllFlatColumns()
      .filter(c => c.columnDef.download !== false || exportAllData)
      .map(c => ({
        download: c.columnDef.headerDownload || camelToSentence(c.columnDef.id),
        id: c.columnDef.id
      }));
  };
  const allHeaders = getAllHeaders();
  const getDownloadData = () =>
    rows.map(r => {
      return r.getAllCells().reduce((a, b: ProcessedCell) => {
        if (typeof b.column.columnDef.download === 'function') {
          a[b.column.columnDef.id] = b.column.columnDef.download(
            r.original[b.column.columnDef.id]
          );
        } else if (allHeaders?.find(h => h.id === b.column.columnDef.id)) {
          a[b.column.columnDef.id] = r.original[b.column.columnDef.id] || '';
        }
        return a;
      }, {});
    });
  const handleExport = useCallback(
    data => {
      downloadCSV(
        data,
        exportTitle,
        h => allHeaders?.find(hh => hh.id === h).download
      );
    },
    [rows]
  );
  const methods = useForm<Record<string, any>, any, Record<string, any>>({
    defaultValues: { headers: [], all: false }
  });
  const handleSubmit = methods.handleSubmit(vals => {
    handleExport(
      getDownloadData().map(d =>
        vals.headers.reduce((a, b) => ({ ...a, [b]: d[b] }), {})
      )
    );
    setShow(false);
  });
  return (
    <ResponsiveModal show={show} onHide={() => setShow(false)}>
      <Modal.Body>
        <FormProvider {...methods}>
          <WizardInput
            name="headers"
            type="checkbox"
            multiple
            formControlProps={{ rows: 10 }}
            options={allHeaders?.map(d => ({
              label: d.download,
              value: d.id
            }))}
            label="Select the fields to export"
            instruction="For data security reasons, it's always best to download only the minimum that you need"
          />
          <WizardInput
            name="all"
            type="checkbox"
            label="Select all"
            registerProps={{
              required: false,
              onChange: e => {
                methods.setValue(
                  'headers',
                  e.target.value ? allHeaders?.map(d => d.id) : []
                );
              }
            }}
          />
        </FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={() => setShow(false)}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Export</Button>
      </Modal.Footer>
    </ResponsiveModal>
  );
};
ExportModal.propTypes = {
  rows: PropTypes.array,
  exportTitle: PropTypes.string,
  show: PropTypes.bool,
  setShow: PropTypes.func,
  exportAllData: PropTypes.bool
};
const DataExporter = ({ fileName }) => {
  const { getPrePaginationRowModel, title } = useAdvanceTable();
  const exportTitle = useMemo(
    () =>
      (fileName || title) +
      ' data ' +
      format(new Date(), 'dd-MM-yyyy HH-mm') +
      '.csv',
    [title, fileName]
  );
  const rows = getPrePaginationRowModel().rows;
  const [show, setShow] = useState(false);
  return (
    <>
      <Button
        variant="falcon-default"
        data-tour="table-data-exporter"
        size="sm"
        disabled={rows.length === 0}
        onClick={() => setShow(true)}
      >
        <FontAwesomeIcon icon="external-link-alt" transform="shrink-3" />
        <span className="ms-1 d-none d-xl-inline">Export</span>
      </Button>
      <ExportModal
        show={show}
        setShow={setShow}
        exportTitle={exportTitle}
        rows={rows}
      />
    </>
  );
};
DataExporter.propTypes = {
  fileName: PropTypes.string
};
const NewRecordButton = ({ onClick }) => {
  return (
    <>
      <Button
        variant="falcon-default"
        size="sm"
        className="tour-btn-new-record"
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        <FontAwesomeIcon icon="plus" transform="shrink-3" />
        <span className="ms-1 d-none d-xl-inline">New</span>
      </Button>
    </>
  );
};
NewRecordButton.propTypes = {
  onClick: PropTypes.func
};
const FilterButton = () => {
  const [filterShow, setFilterShow] = useState(false);
  const { filters = [] } = useAdvanceTable();
  return (
    <>
      <Button
        variant={
          filters.filter(f => f.value?.active).length === 0
            ? 'falcon-default'
            : 'primary'
        }
        size="sm"
        className="tour-btn-filter"
        onClick={() => setFilterShow(true)}
      >
        <FontAwesomeIcon icon="filter" transform="shrink-3" />
        <span className="ms-1 d-none d-xl-inline">Filter</span>
      </Button>
      <AdvanceTableFilters
        filterShow={filterShow}
        setFilterShow={setFilterShow}
      />
    </>
  );
};
const TableActions = () => {
  const { getTableActions } = useAdvanceTable();
  return getTableActions()?.map((action, i) => (
    <Button
      key={i}
      variant="falcon-default"
      size="sm"
      disabled={
        typeof action.disabled === 'function'
          ? action.disabled(null)
          : action.disabled
      }
      onClick={() => action.onClick(null)}
    >
      <FontAwesomeIcon icon={action.icon} transform="shrink-3" />
      <span className="ms-1 d-none d-xl-inline">{action.name}</span>
    </Button>
  ));
};
const TableHeader = ({
  autoFocus,
  exportConfig,
  newRecordConfig = { onClick: null },
  filterConfig,
  bulkActions = [],
  setEditing,
  edit
}: {
  autoFocus?: boolean;
  exportConfig?: any;
  newRecordConfig?: any;
  filterConfig?: any;
  bulkActions?: BulkAction<any>[];
  setEditing?: any;
  edit?: any;
}) => {
  const {
    getFilteredSelectedRowModel,
    getIsSomeRowsSelected,
    getIsAllRowsSelected,
    toggleAllRowsSelected,
    setGlobalFilter,
    getOnNewClick,
    resetRowSelection,
    isLoading
  } = useAdvanceTable();
  const onNewClick = getOnNewClick();
  if (onNewClick) {
    newRecordConfig.onClick = onNewClick;
  }
  const bulkActionsWithDefaults = useMemo(() => {
    const defaults = [];
    if (edit) {
      defaults.push({
        name: 'Edit',
        icon: faEdit,
        actionFn: rows => {
          // const sharedData = getCommonDataValues();
          // setBulkEditData(sharedData);
          // setBulkEditShow(true);
          setEditing(rows);
        }
      });
    }
    return bulkActions.concat(defaults);
  }, [bulkActions]);
  const [applying, startApply] = useTransition();
  function handleBulkApply(val) {
    const selectedRows = getFilteredSelectedRowModel().rows;
    startApply(() => {
      const action = bulkActionsWithDefaults.find(a => a.name == val);
      if (action && action.actionFn) {
        const confirm =
          typeof action.confirm === 'function'
            ? action.confirm(selectedRows)
            : typeof action.confirm === 'string' && action.confirm;
        if (confirm) {
          return setShowConfirm({
            text:
              typeof confirm === 'string'
                ? confirm
                : 'Perform the selected action on ' +
                  selectedRows.length +
                  ' rows?',
            onConfirm: () => {
              action.actionFn(selectedRows);
            }
          });
        }
        action.actionFn(selectedRows);
      }
      resetRowSelection();
    });
  }
  const [showConfirm, setShowConfirm] = useState<{
    text: string | boolean;
    onConfirm: () => void;
  }>();
  return (
    !isLoading && (
      <>
        <Row className="flex-end-center flex-md-nowrap gy-2 mx-3 mx-md-0">
          {getIsSomeRowsSelected() || getIsAllRowsSelected() ? (
            <Col xs="auto" className="d-flex gap-2">
              <>
                <ModalConfirm
                  body={showConfirm?.text}
                  onConfirm={showConfirm?.onConfirm}
                  extShow={!!showConfirm}
                  onHide={() => setShowConfirm(null)}
                />
                {bulkActionsWithDefaults.length > 0 && (
                  <Form.Group>
                    {applying ? (
                      <Skeleton />
                    ) : (
                      <Form.Select
                        className={'w-auto'}
                        size="sm"
                        aria-label="Bulk actions"
                        onChange={e => handleBulkApply(e.target.value)}
                      >
                        <option value="">Bulk Actions</option>
                        {bulkActionsWithDefaults.map(a => (
                          <option key={a.name}>{a.name}</option>
                        ))}
                      </Form.Select>
                    )}
                  </Form.Group>
                )}

                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon={faTimes}
                  transform="shrink-3"
                  onClick={() => toggleAllRowsSelected(false)}
                >
                  <span className="ms-1">Clear</span>
                </IconButton>
              </>
            </Col>
          ) : (
            <>
              <Col xs="auto" className="px-0 me-md-3">
                <AdvanceTableSearchBox
                  className={'w-auto'}
                  formControlProps={{ autoFocus: autoFocus }}
                  onChange={setGlobalFilter}
                />
              </Col>
              {/* <Col xs={'auto'} sm={3} md={4} xl={3} className="ps-0 ps-sm-2 px-0">
              <SortButton />
            </Col> */}
              <Col
                xs={'auto'}
                className="d-inline-flex flex-end-center px-0 gap-2"
              >
                {newRecordConfig?.onClick && (
                  <NewRecordButton {...newRecordConfig} />
                )}
                <FilterButton {...filterConfig} />
                <DataExporter {...exportConfig} />
                <TableActions />
              </Col>
            </>
          )}
        </Row>
      </>
    )
  );
};

TableHeader.propTypes = {
  autoFocus: PropTypes.bool,
  exportConfig: PropTypes.object,
  newRecordConfig: PropTypes.object,
  filterConfig: PropTypes.object,
  bulkActions: PropTypes.array,
  setEditing: PropTypes.func,
  edit: PropTypes.array
};
const AdvanceTableHeader = ({
  autoFocus,
  isFetching,
  bulkActions,
  setEditing,
  edit
}) => {
  const { title } = useAdvanceTable();
  return (
    <FalconCardHeader
      className="sticky-top top-navbar-height"
      breakPoint="xl"
      title={title}
      light
    >
      <TableHeader
        setEditing={setEditing}
        autoFocus={autoFocus}
        bulkActions={bulkActions}
        edit={edit}
      />
      <div className={'fs--2 p-2 text-primary position-absolute top-0 start-0'}>
        {isFetching && (
          <Flex alignItems={'center'} direction={'column'}>
            <Spinner animation="grow" size="sm" />
          </Flex>
        )}
      </div>
    </FalconCardHeader>
  );
};
AdvanceTableHeader.propTypes = {
  autoFocus: PropTypes.bool,
  isFetching: PropTypes.bool,
  bulkActions: PropTypes.array,
  setEditing: PropTypes.func,
  edit: PropTypes.array
};
export default AdvanceTableHeader;
