import { useMemo } from 'react';
import { useUser } from './useUser';
import { useIsAllowed } from './useGuard';
import { UserRoles } from 'apis/flex/users';
export default () => {
  const { roles } = useUser();
  const userRoles: UserRoles = useMemo(() => roles || {}, [roles]);
  const isAllowed = useIsAllowed();
  return { userRoles, isAllowed };
};
