import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { FormQuestion, customFormsApi } from 'apis/flex/customForms';
import { useMemo } from 'react';

export default <TSelected = FormQuestion[]>({
  formId,
  select,
  ...rest
}: {
  formId?: number;
  select?: (data: FormQuestion[]) => TSelected;
} & Partial<
  Omit<UseQueryOptions<FormQuestion[], Error, TSelected>, 'enabled' | 'select'>
>) => {
  const enabled = useMemo(() => !!formId, [formId]);
  return useQuery<FormQuestion[], Error, TSelected>({
    queryKey: ['CustomForms', 'CustomFormQuestions', formId],
    queryFn: () => {
      if (!formId) return Promise.resolve([]);
      return customFormsApi
        .getOne(formId)
        .then(d => d[0]?.sections?.map(s => s.questions).flat(2));
    },
    enabled,
    select,
    staleTime: Infinity,
    ...rest
  });
};
