import { useEffect, useState } from 'react';
import useCampaign from '../../campaigns/hooks/useCampaign';
import { Applicant } from 'apis/flex/recruitment';
import useStages from './useStages';

export default ({ campaignId }) => {
  const [items, setItems] = useState<
    { title: string; filter: (a: Applicant) => boolean }[]
  >([]);
  const { data } = useStages({
    campaignId
  });
  const basicItems = [
    {
      title: 'New',
      filter: a => !a?.acceptReject && !a?.currentStage?.stageId
    },
    {
      title: 'Accepted',
      filter: a => a?.acceptReject === 'accepted'
    },
    {
      title: 'Rejected',
      filter: a => a?.acceptReject === 'rejected'
    }
  ];
  useEffect(() => {
    const newNavItems = [...basicItems];
    data?.forEach(stage => {
      newNavItems.splice(1, 0, {
        title: stage.name,
        filter: a => !a?.acceptReject && a?.currentStage?.stageId == stage.id
      });
    });
    setItems([...newNavItems]);
  }, [data]);
  return items;
};
