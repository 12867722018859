import { customFormsResponseApi, FormResponse } from 'apis/flex/customForms';
import useDefaultCrud, { UseCrudProps } from 'hooks/useDefaultCrud';
import { useMemo } from 'react';

export default <TSelected = FormResponse[]>({
  formId,
  includeScreenouts,
  ...rest
}: {
  formId?: number;
  includeScreenouts?: boolean;
} & UseCrudProps<FormResponse, TSelected>) => {
  const enabled = useMemo(() => !!formId, [formId]);
  return useDefaultCrud<FormResponse, TSelected>(
    'CustomFormResponses',
    customFormsResponseApi,
    {
      enabled,
      filters: {
        formId,
        screenoutQuestionIds: includeScreenouts ? null : 'null'
      },
      useFilter: true,
      retryOnMount: false,
      staleTime: 1000 * 20,
      ...rest
    }
  );
};
