import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  EventName,
  markNotificationAsActioned,
  markNotificationAsSeen
} from 'apis/flex/notifications';
import { useCallback, useState } from 'react';

export default ({
  domain: _domain,
  eventName: _eventName,
  id: _id,
  itemId: _itemId
}: {
  domain?: string;
  eventName?: EventName | EventName[];
  id?: number | number[];
  itemId?: number | number[];
} = {}) => {
  const queryClient = useQueryClient();
  const [alreadySent, setAlreadySent] = useState(new Set<any>());
  const { mutate } = useMutation<
    any,
    Error,
    {
      domain?: string;
      eventName?: EventName | EventName[];
      id?: number | number[];
      itemId?: number | number[];
      fn: (info: {
        eventName?: EventName | EventName[];
        domain?: string;
        id?: number | number[];
        itemId?: number | number[];
      }) => Promise<any>;
      alreadySent: Set<any>;
    }
  >({
    mutationKey: ['notificationsActioned'],
    mutationFn: ({
      domain = _domain,
      eventName = _eventName,
      id = _id,
      itemId = _itemId,
      fn,
      alreadySent
    }) => {
      // console.log(
      //   'checking already sent',
      //   {
      //     domain,
      //     eventName,
      //     id,
      //     itemId,
      //     alreadySent
      //   },
      //   alreadySent.has(JSON.stringify({ domain, eventName, id, itemId }))
      // );
      if (alreadySent.has(JSON.stringify({ domain, eventName, id, itemId }))) {
        // console.log('already sent', {
        //   domain,
        //   eventName,
        //   id,
        //   itemId,
        //   alreadySent
        // });
        return Promise.resolve();
      }
      // console.log('sending', { domain, eventName, id, itemId, alreadySent });
      setAlreadySent(
        alreadySent.add(JSON.stringify({ domain, eventName, id, itemId }))
      );
      return fn({
        eventName,
        domain,
        id,
        itemId
      });
    },
    onSuccess: d => {
      if (d) {
        queryClient.invalidateQueries(['notifications']);
        queryClient.invalidateQueries(['Notifications']);
      }
    }
  });
  const setActioned = useCallback(
    ({
      domain,
      eventName,
      id,
      itemId
    }: {
      domain?: string;
      eventName?: EventName | EventName[];
      id?: number | number[];
      itemId?: number | number[];
    } = {}) => {
      return mutate({
        domain,
        eventName,
        id,
        itemId,
        fn: markNotificationAsActioned,
        alreadySent
      });
    },
    [markNotificationAsActioned, mutate, alreadySent]
  );
  const setSeen = useCallback(
    ({
      domain,
      eventName,
      id,
      itemId
    }: {
      domain?: string;
      eventName?: EventName | EventName[];
      id?: number | number[];
      itemId?: number | number[];
    } = {}) => {
      return mutate({
        domain,
        eventName,
        id,
        itemId,
        fn: markNotificationAsSeen,
        alreadySent
      });
    },
    [markNotificationAsActioned, mutate, alreadySent]
  );
  return {
    setActioned,
    setSeen
  };
};
