import { useFormContext } from 'react-hook-form';

export default <T extends { [key: string]: any }>({
  index,
  onlyOnce: onlyOnceMain
}: {
  index?: number;
  onlyOnce?: boolean;
}) => {
  const { setValue, getValues } = useFormContext();
  const setResourceValue = (
    fieldName: keyof T,
    value: any,
    onlyOnce: boolean = onlyOnceMain,
    _index: number = index
  ) => {
    const current = getValues(`resources.${_index}.${String(fieldName)}`);
    if (!current || !onlyOnce) {
      setValue(`resources.${_index}.${String(fieldName)}`, value, {
        shouldDirty: true
      });
    }
  };
  return {
    setValue: setResourceValue,
    getValues,
    getValue: (fieldName: string, _index: number = index) =>
      getValues(`resources.${_index}.${fieldName}`)
  };
};
