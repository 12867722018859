import api from '.';
import CrudApi from './CrudApi';

const baseUrl = 'pm/';

export type ClientContact = {
  id: number;
  clientId: number;
  firstName: string;
  surname: string;
  email?: string;
  secondaryEmail?: string;
  telephone?: string;
  secondaryTelephone?: string;
  createdDate: Date;
  createdBy: number;
  companyId?: number;
};
export type Client = {
  id: number;
  name: string;
  website?: string;
  telephone?: string;
  email?: string;
  accountsEmail?: string;
  createdDate: Date;
  createdBy: number;
  contacts: ClientContact[];
};
export const clientsApi = new CrudApi<Client>(baseUrl + 'clients');

export type Project = {
  id: number;
  quoteId?: number;
  clientId?: number;
  clientContactId: number;
  name: string;
  createdDate: Date;
  createdBy: number;
  quoteRef?: string;
  contactName?: string;
  clientName?: string;
};
export const projectsApi = new CrudApi<Project>(baseUrl + 'projects');

export type Quote = {
  id: number;
  reference: string;
  name: string;
  createdDate: Date;
  createdBy: number;
  clientId?: number;
  clientContactId?: number;
  status?: string;
};
export const quotesApi = new CrudApi<Quote>(baseUrl + 'quotes');

export type TargetGroup = {
  id: number;
  name: string;
  createdDate: Date;
  createdBy: number;
  projectId: number;
  targetTypeId: number;
  target: number;
  startDate?: Date;
  endDate?: Date;
};
export const targetGroupsApi = new CrudApi<TargetGroup>(
  baseUrl + 'target-groups'
);
export type TargetType = {
  id: number;
  name: string;
};
export const targetTypesApi = new CrudApi<TargetType>(baseUrl + 'target-types');

export type TeamRole = {
  id: number;
  name: string;
  createdDate: Date;
  createdBy: number;
};
export const teamRolesApi = new CrudApi<TeamRole>(baseUrl + 'team-roles');

export type ProjectRole = {
  id: number;
  projectId: number;
  userId: number;
  roleId: number;
  createdDate: Date;
  createdBy: number;
  roleName?: string;
};
export const projectRolesApi = new CrudApi<ProjectRole>(
  baseUrl + 'project-roles'
);
