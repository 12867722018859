import React from 'react';
import {
  ProcessedColumn,
  useAdvanceTable
} from 'components/common/advance-table-v2/AdvanceTableProvider';
import { HTMLProps } from 'react';
import { getCommonDataValues } from 'helpers/utils';
import { Row } from '@tanstack/react-table';
import { ResponsiveModal } from 'components/common/Modals';
import { Button, Modal } from 'react-bootstrap';
import DomainItemIcon from 'components/common/DomainItemIcon';
import { useQuery } from '@tanstack/react-query';
import { FilterValueInput } from 'components/common/advance-table-v2/AdvanceTableFilters';
import Flex from 'components/common/Flex';
import WizardInput, { WizardInputProps } from 'components/wizard/WizardInput';
import { FormProvider, useFormContext, useWatch } from 'react-hook-form';
import useMediaForm from 'hooks/useMediaForm';
import { domainConfigs } from 'components/notification/config';
import { EventPrefix } from 'apis/flex/notifications';
import LoadingButton from '../LoadingButton';
import { UseDefaultCrudReturn } from 'hooks/useDefaultCrud';
const useColumnOptions = <TData extends { id?: number } = any>(
  column: ProcessedColumn<TData>
) => {
  const def = column.columnDef.meta;
  const query = def.optionsQuery;
  const options = def.options;
  return useQuery({
    queryKey: ['SqlOptions', column.columnDef.id, query, options],
    ...(query || {}),
    initialData: options,
    enabled: !!query,
    staleTime: Infinity
  });
};
const EditorField = <TData extends { id?: number } = any>({
  field,
  inputProps,
  ...rest
}: {
  field: string;
  inputProps?: WizardInputProps;
} & HTMLProps<HTMLDivElement>) => {
  const table = useAdvanceTable();
  const column = table.getColumn(field) as ProcessedColumn<TData>;
  const { data: options } = useColumnOptions(column);
  console.log('options', column, options);
  const active = useWatch({ name: field + '.active' });
  const { setValue } = useFormContext();
  return (
    <Flex alignItems={'start'} className={'w-100 position-relative'} {...rest}>
      <WizardInput
        name={field + '.active'}
        type="switch"
        registerProps={{ required: false }}
        hideLabel
      />
      <FilterValueInput
        // label={column.columnDef.header}
        // hideLabel={false}
        formGroupProps={{ className: 'flex-1' }}
        column={{
          id: field,
          inputType: column.columnDef.meta.type,
          ...column.columnDef.meta,
          filter:
            typeof column.columnDef.meta.filter === 'boolean'
              ? undefined
              : column.columnDef.meta.filter
        }}
        size="md"
        options={options}
        getFilterName={x => field + `.${x}`}
        {...inputProps}
        multiple={!!column.columnDef.meta.multiple}
      />
      {!active && (
        <div
          className="position-absolute w-100 h-100 bg-300 bg-opacity-10"
          onClick={() => {
            setValue(field + '.active', true);
            // setValue(getFilterName('type'), types[0].value);
            // setFocus(getFilterName('name'));
          }}
        ></div>
      )}
    </Flex>
  );
};
type EditorValue = {
  active: boolean;
  value: any;
  type: '==';
};
const AdvanceEditor = <TData extends { id?: number } = any>({
  data,
  domain,
  onUpdate,
  isUpdating,
  crudHook,
  fields: fieldKeys
}: {
  data?: TData[];
  crudHook?: Pick<
    UseDefaultCrudReturn<TData>,
    'data' | 'bulkUpdate' | 'isBulkUpdating'
  >;
  domain?: EventPrefix;
  onUpdate?: (rows: Partial<Row<TData>>[], data: Partial<TData>) => void;
  isUpdating?: boolean;
  fields?: (keyof TData)[];
}) => {
  const domainConfig = domainConfigs[domain];
  const { editing, setEditing } = useAdvanceTable<TData>();
  const rows = editing;
  const commonData = getCommonDataValues(rows?.map(x => x.original));
  const fields = Object.keys(fieldKeys).filter(x => fieldKeys[x]);
  const defaultValues = fields.reduce(
    (a, b) => ({
      ...a,
      [b]: {
        active: false,
        value: commonData[b] || '',
        type: '=='
      }
    }),
    {}
  );
  const methods = useMediaForm<Record<string, EditorValue>>({
    values: defaultValues,
    defaultValues
  });
  const apiUpdater = crudHook;
  const getUpdater = () => {
    if (onUpdate) {
      return v => onUpdate(rows, v);
    } else {
      return v =>
        apiUpdater.bulkUpdate(
          { ids: rows.map(r => r.original.id), data: v },
          { onSuccess: () => setEditing([]) }
        );
    }
  };
  const handleSubmit = methods.handleSubmit(vals => {
    const activeVals = Object.keys(vals).reduce((a, b) => {
      if (vals[b].active) {
        a[b] = vals[b].value;
      }
      return a;
    }, {} as Partial<TData>);
    const updater = getUpdater();
    updater(activeVals);
  }, console.error);
  return (
    <ResponsiveModal show={rows?.length > 0} onHide={() => setEditing([])}>
      <FormProvider {...methods}>
        <Modal.Body>
          <div className="p-3">
            <h6>
              {'Editing ' +
                (rows?.length || 0) +
                ' items' +
                (rows?.length === 1 ? '' : 's')}
            </h6>
            {domain && (
              <DomainItemIcon
                size="sm"
                domain={domain}
                data={rows?.map(r => domainConfig.format(r.original))}
              />
            )}
          </div>
          {fields?.map(field => (
            <EditorField key={field} field={field} />
          ))}
        </Modal.Body>
        <Modal.Footer className="text-end">
          <Button variant="link" onClick={() => setEditing([])}>
            Cancel
          </Button>
          <LoadingButton
            loading={isUpdating || apiUpdater?.isBulkUpdating}
            onClick={handleSubmit}
          >
            Update
          </LoadingButton>
        </Modal.Footer>
      </FormProvider>
    </ResponsiveModal>
  );
};
export default AdvanceEditor;
