import companyConfig from 'companyConfig';
import Linkify from 'linkify-react';
import React, { ReactNode } from 'react';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const toast500 = (msg?: ReactNode) => {
  return toast.error(
    () => (
      <Linkify>
        {msg ||
          'Something went wrong! Please try again, or contact ' +
            companyConfig.supportEmail}
      </Linkify>
    ),
    { containerId: 'default' }
  );
};
export const apiError = err => {
  Sentry.captureException(err);
  if (err.response?.status === 403) {
    return toast.warning(
      () => (
        <>
          Unauthorised!
          {err.response?.data?.requiredPermissions && (
            <div className="form-text">
              One of the following permissions is required:{' '}
              {err.response?.data?.requiredPermissions.join(', ')}
            </div>
          )}
        </>
      ),
      {
        containerId: 'default',
        icon: <FontAwesomeIcon icon="exclamation-triangle" />
      }
    );
  }
  if (err.response?.status === 409) {
    return toast500(err.data?.message);
  }
  if (err.response?.status === 401) {
    // setItemToStore('client-access-token', null);
    // return toast500(err.response?.data?.message);
  }
  return toast500();
};
export const apiPromise = (
  promise: Promise<any>,
  messages: Partial<Record<'pending' | 'success' | 'error', string>> = {
    pending: 'Working on it..',
    success: 'Done!',
    error: 'Something went wrong! Please try again'
  }
) => {
  return toast.promise(promise, messages, {
    containerId: 'default',
    icon: (
      <Spinner
        animation="border"
        style={{ height: 20 }}
        variant="primary"
        size="sm"
      />
    )
  });
};
export const apiSuccess = (msg?: ReactNode) => {
  if (msg && typeof msg === 'string') {
    return toast.success(msg, { containerId: 'default' });
  }
  return toast.success('Done!', { containerId: 'default' });
};
export const apiCustomError = (msg?: ReactNode) => {
  return err => {
    console.error(err);
    return toast500(msg);
  };
};
