import React, {
  CSSProperties,
  HTMLProps,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Form,
  OverlayTrigger,
  Popover,
  Table
} from 'react-bootstrap';
import {
  ProcessedCell,
  ProcessedColumn,
  ProcessedColumnDef,
  ProcessedRow,
  useAdvanceTable
} from './AdvanceTableProvider';
import {
  Column,
  HeaderGroup,
  Row as RowType,
  flexRender
} from '@tanstack/react-table';
import Flex from '../Flex';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import SimpleBar from 'simplebar-react';
import DropdownContextWrapper from '../DropdownContextWrapper';
import {
  FilterFormValues,
  FilterTypeInput,
  FilterValueInput,
  TableFilterProvider
} from './AdvanceTableFilters';
import { useFormContext, useWatch } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faThumbtack } from '@fortawesome/free-solid-svg-icons';
import PageSlider from './PageSlider';
import CustomTooltip from '../Tooltip';
import styled from 'styled-components';
import RenderWhenVisible from '../RenderWhenVisible';
import Wrapper from 'helpers/Wrapper';
export type ConfigCallbackProps = {
  data: any;
  cells: ProcessedCell[];
  row: RowType<any>;
  onRowClick: (row: RowType<any>) => void;
};
//These are the important styles to make sticky column pinning work!
//Apply styles like this using your CSS strategy of choice with this kind of logic to head cells, data cells, footer cells, etc.
//View the index.css file for more needed styles such as border-collapse: separate
const getCommonPinningStyles = (column: Column<any>): CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn =
    isPinned === 'left' && column.getIsLastColumn('left');
  const isFirstRightPinnedColumn =
    isPinned === 'right' && column.getIsFirstColumn('right');

  return {
    boxShadow: isLastLeftPinnedColumn
      ? '-4px 0 4px -4px gray inset'
      : isFirstRightPinnedColumn
      ? '4px 0 4px -4px gray inset'
      : undefined,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    opacity: isPinned ? 0.95 : 1,
    position: isPinned ? 'sticky' : 'relative',
    width: column.getSize(),
    zIndex: isPinned ? 1 : 0,
    backgroundColor: isPinned
      ? 'rgba(var(--falcon-gray-100-rgb), 1) !important'
      : undefined
  };
};
const HoverTd = styled.td`
  :hover {
  }
`;
const HoverTdComponent = ({
  ref,
  as,
  ...props
}: HTMLProps<HTMLTableCellElement>) => <HoverTd {...props} />;
const HoverTh = styled.th`
  :hover {
  }
`;
const HoverThComponent = ({
  ref,
  as,
  ...props
}: HTMLProps<HTMLTableCellElement>) => <HoverTh {...props} />;
const RowCells = ({
  row,
  onContextMenu,
  refreshTrigger
}: {
  row: RowType<any>;
  onContextMenu: (row: RowType<any>) => any;
  refreshTrigger?: boolean;
}) => {
  const rowData = row.original;
  const cells = useMemo(
    () =>
      row.getVisibleCells().map((cell: ProcessedCell, i) => {
        return (
          <HoverTdComponent
            key={cell.column.columnDef.id}
            {...cell.column.columnDef.cellProps}
            className={classNames(
              cell.column.columnDef.cellProps?.className ||
                'px-3 py-1 overflow-hidden',
              {
                'text-wrap': !!cell.column.columnDef.wrap
              }
            )}
            style={{
              width: cell.column.columnDef.wrap
                ? cell.column.columnDef.wrap + 'px'
                : cell.column.getSize() + 'px',
              maxWidth: cell.column.columnDef.wrap ? 300 : undefined,
              textOverflow: 'ellipsis',
              ...getCommonPinningStyles(cell.column)
            }}
            onContextMenu={
              process.env.NODE_ENV === 'production'
                ? onContextMenu(row)
                : undefined
            }
          >
            <Wrapper
              condition={i > 8}
              wrapper={c => (
                <RenderWhenVisible height="1rem">{c}</RenderWhenVisible>
              )}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Wrapper>
          </HoverTdComponent>
        );
      }),
    [rowData, refreshTrigger]
  );
  return <>{cells}</>;
};

const TableRows = ({
  rowClassName,
  getOnRowClick,
  getRows,
  onContextMenu,
  loadingIndices,
  refreshTrigger
}: {
  rowClassName: string;
  getOnRowClick: () => (row: RowType<any>, e: any) => void;
  getRows: () => ProcessedRow[];
  onContextMenu: (row: RowType<any>) => (e: any) => void;
  loadingIndices?: number[];
  refreshTrigger?: boolean;
}) => {
  return getRows().map(row => {
    return (
      <>
        <tr
          key={row.original.id}
          className={classNames(
            'align-middle white-space-nowrap hover-actions-trigger btn-reveal-trigger hover-bg-100 cursor-pointer',
            rowClassName
          )}
          onClick={e => getOnRowClick()(row, e)}
        >
          <RowCells
            row={row}
            onContextMenu={onContextMenu}
            refreshTrigger={refreshTrigger}
          />
        </tr>
        {loadingIndices?.includes(row.index) && (
          <tr>
            <td colSpan={100}>
              <Skeleton height={34} />
            </td>
          </tr>
        )}
      </>
    );
  });
};
const TableRow = ({ children }) => <tr>{children}</tr>;
TableRow.propTypes = {
  children: PropTypes.node
};
const FilterItem = ({ column, onFinished }) => {
  const { handleSubmit, setValue, getValues } = useFormContext<
    any,
    any,
    FilterFormValues
  >();
  const isActive =
    useWatch({ name: column.id + '.active' }) ||
    getValues(column.id + '.active');
  const handleClear = () => {
    setValue(column.id + '.active', false);
    setTimeout(handleSubmit(onFinished), 10);
  };
  useEffect(() => {
    setTimeout(() => setValue(column.id + '.active', true), 100);
  }, [setValue, column?.id]);
  return (
    <Form onSubmit={handleSubmit(onFinished)}>
      <div className="position-relative">
        <FilterTypeInput
          getFilterName={x => column.id + '.' + x}
          column={column}
        />
        <FilterValueInput
          column={column}
          getFilterName={x => column.id + '.' + x}
        />
        {!isActive && (
          <div
            className="position-absolute w-100 top-0 start-0 h-100 bg-300 bg-opacity-10"
            onClick={() => {
              setValue(column.id + '.active', true);
            }}
          ></div>
        )}
      </div>
      <Flex justifyContent={'end'}>
        <Button
          size="sm"
          className="me-1"
          variant="secondary"
          onClick={handleClear}
        >
          Clear
        </Button>
        <Button size="sm" type="submit">
          Apply
        </Button>
      </Flex>
    </Form>
  );
};
const FilterIcon = ({ column, setShow }) => {
  const { filters } = useAdvanceTable();
  return (
    <Button
      variant="link"
      onClick={() => setShow(true)}
      className={classNames('p-0 ps-1', {
        'notification-indicator notification-indicator-primary': filters.some(
          x => x.id === column.id && x.value?.active
        )
      })}
    >
      <FontAwesomeIcon className={classNames('text-500')} icon={faEllipsisV} />
    </Button>
  );
};
const FilterDropdown = ({ column }: { column: ProcessedColumnDef }) => {
  const [show, setShow] = useState(false);
  return (
    <OverlayTrigger
      show={show}
      onToggle={show => setShow(show)}
      // defaultShow={show}
      placement="bottom"
      rootClose
      trigger={['click', 'focus']}
      overlay={
        <Popover show={true}>
          <Popover.Body>
            <TableFilterProvider>
              <FilterItem column={column} onFinished={() => setShow(false)} />
            </TableFilterProvider>
          </Popover.Body>
        </Popover>
      }
    >
      {({ ref }) => (
        <div ref={ref}>
          <FilterIcon column={column} setShow={setShow} />
        </div>
      )}
    </OverlayTrigger>
  );
};
const Pinner = ({
  column,
  onPin
}: {
  column: Column<any, any>;
  onPin: () => void;
}) => {
  return (
    <Button variant="link" onClick={onPin} className="p-0 pe-1">
      <FontAwesomeIcon
        size="xs"
        icon={faThumbtack}
        className={classNames({
          'text-secondary': !column.getIsPinned(),
          'text-primary': column.getIsPinned()
        })}
      />
    </Button>
  );
};
const HeaderGroupRow = ({
  group,
  onPinChange
}: {
  group: HeaderGroup<any>;
  onPinChange: () => void;
}) => {
  return (
    <tr key={group.id}>
      {group.headers.map(header => {
        const col = header.column as ProcessedColumn;
        const sortDir = header.column.getIsSorted();
        return (
          <HoverThComponent
            key={header.id}
            className={'px-3'}
            width={header.getSize() + 'px'}
            {...col.columnDef.headerProps}
            style={{
              verticalAlign: 'baseline',
              ...getCommonPinningStyles(header.column)
            }}
          >
            {header.isPlaceholder ? null : (
              <Flex alignItems={'baseline'} justifyContent="between">
                <div className="d-flex align-items-center">
                  {header.column.getCanPin() && (
                    <Pinner
                      column={header.column}
                      onPin={() => {
                        header.column.getIsPinned()
                          ? header.column.pin(false)
                          : header.column.pin('left');
                        onPinChange();
                      }}
                    />
                  )}
                  <div
                    {...{
                      className:
                        'd-flex align-items-center ' +
                        (header.column.getCanSort()
                          ? 'cursor-pointer select-none'
                          : ''),
                      onClick: header.column.getToggleSortingHandler()
                    }}
                  >
                    <CustomTooltip content={header.column.columnDef.header}>
                      <div
                        style={{ whiteSpace: 'nowrap', maxWidth: 150 }}
                        className="text-truncate"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    </CustomTooltip>
                    {sortDir !== false &&
                      {
                        asc: <span className="sort asc pb-1" />,
                        desc: <span className="sort desc pb-1" />
                      }[sortDir]}
                  </div>
                </div>
                {header.column.getCanFilter() && (
                  <FilterDropdown column={col.columnDef} />
                )}
              </Flex>
            )}
          </HoverThComponent>
        );
      })}
    </tr>
  );
};
const SelectionListener = ({ onSelectionChange }) => {
  const { getSelectedRowModel } = useAdvanceTable();
  const [selected, setSelected] = useState([]);
  // console.log('selectedRowModel', getSelectedRowModel, getSelectedRowModel?.());
  const s = getSelectedRowModel?.().rows.map(r => r.id);
  useEffect(() => {
    if (!s) return;
    if (
      s.every(ss => selected?.includes(ss)) &&
      selected?.every(ss => s.includes(ss))
    )
      return;
    setSelected(s);
  }, [s]);
  useEffect(() => {
    onSelectionChange(selected);
  }, [selected]);
  return <></>;
};
const TableView = ({
  headerClassName,
  bodyClassName,
  rowClassName,
  tableProps,
  isLoading,
  emptyPlaceholder,
  loadingIndices,
  tableRef
}) => {
  const {
    RowContextMenu,
    getRowModel,
    pagination,
    id,
    dataId,
    animateDirection,
    getOnRowClick,
    getHeaderGroups,
    resetRowSelection
  } = useAdvanceTable();
  const numRows = useMemo(() => {
    // console.log('dataId changed', dataId, getRowModel().rows);
    return getRowModel().rows.length;
  }, [dataId, getRowModel]);
  // console.log('dataId', dataId, numRows);
  const [menuRow, setMenuRow] = useState();
  const handleContext = row => e => {
    e.preventDefault();
    setMenuRow(row);
  };
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const tableId = isLoading
    ? id + '-loading'
    : id + '-page-' + pagination?.pageIndex;
  useEffect(() => {
    resetRowSelection();
  }, [dataId]);
  const onPinChange = useCallback(() => setRefreshTrigger(prev => !prev), []);
  return (
    <div
      // layout
      className="table-responsive overflow-visible"
      style={{ minHeight: 200 }}
    >
      <SelectionListener
        onSelectionChange={() => setRefreshTrigger(!refreshTrigger)}
      />
      {numRows === 0 && !isLoading ? (
        <div className="w-100 text-center p-3 fw-semi-bold text-500 fs-2">
          {emptyPlaceholder}
        </div>
      ) : (
        <DropdownContextWrapper
          menuItems={
            RowContextMenu && menuRow && <RowContextMenu row={menuRow} />
          }
        >
          <PageSlider
            motionKey={tableId + '-' + pagination?.pageIndex}
            direction={
              pagination?.isForward === false || animateDirection === 'backward'
                ? 'backward'
                : 'forward'
            }
          >
            <Table
              // ref={tableRef}
              ref={tableRef}
              className="fs--1 fs-xxl-0 mb-0 table-sm"
              {...tableProps}
            >
              <thead
                className={classNames(
                  'bg-light text-800 align-middle',
                  headerClassName
                )}
              >
                {getHeaderGroups().map(group => (
                  <HeaderGroupRow
                    key={group.id}
                    group={group}
                    onPinChange={onPinChange}
                  />
                ))}
              </thead>
              <tbody className={bodyClassName}>
                {isLoading ? (
                  Array(4)
                    .fill({})
                    .map((row, i) => {
                      return (
                        <tr key={i}>
                          {getHeaderGroups().map(group =>
                            group.headers.map((col, key) => (
                              <td key={key}>
                                <Skeleton />
                              </td>
                            ))
                          )}
                        </tr>
                      );
                    })
                ) : (
                  <TableRows
                    refreshTrigger={refreshTrigger}
                    rowClassName={rowClassName}
                    getRows={() => getRowModel().rows as ProcessedRow[]}
                    getOnRowClick={getOnRowClick}
                    onContextMenu={handleContext}
                    loadingIndices={loadingIndices}
                  />
                )}
              </tbody>
            </Table>
          </PageSlider>
        </DropdownContextWrapper>
      )}
    </div>
  );
};
const AdvanceTable = ({
  headerClassName,
  bodyClassName,
  rowClassName,
  tableProps,
  isLoading,
  onRowClick,
  emptyPlaceholder = 'No records to show',
  loadingIndices = [],
  tableRef
}: // state = {}
{
  headerClassName?: string;
  bodyClassName?: string;
  rowClassName?: string;
  tableProps?: any;
  isLoading?: boolean;
  cardMode?: boolean;
  cardConfig?: any;
  onRowClick?: any;
  emptyPlaceholder?: string;
  loadingIndices?: number[];
  tableRef?: Ref<HTMLTableElement>;
}) => {
  const {
    getHeaderGroups,
    getRowModel,
    isLoading: providerLoading,
    getOnRowClick
  } = useAdvanceTable();
  const anyLoading = isLoading || providerLoading;
  const anyRowClick = useCallback(
    () => onRowClick || getOnRowClick() || function () {},
    [onRowClick, getOnRowClick]
  );
  return (
    <Card.Body className="p-0 overflow-visible">
      <SimpleBar className="overflow-visible">
        <TableView
          {...{
            tableRef,
            headerClassName,
            bodyClassName,
            rowClassName,
            tableProps,
            isLoading: anyLoading,
            getOnRowClick: anyRowClick,
            getRowModel,
            getHeaderGroups,
            emptyPlaceholder,
            loadingIndices
          }}
        />
      </SimpleBar>
    </Card.Body>
  );
};
export default AdvanceTable;
