import { useQuery } from '@tanstack/react-query';
import { getMediaUrl } from 'apis/flex/helpers';
import { FileId } from 'apis/types';

export default ({ fileId }: { fileId: FileId }) => {
  return useQuery({
    enabled: !!fileId,
    queryKey: ['MediaUrl', fileId],
    queryFn: () => getMediaUrl(fileId),
    staleTime: 1000 * 60 * 60
  });
};
