import React from 'react';
import SoftBadge from '../SoftBadge';
import PropTypes from 'prop-types';

const DiffBadge = ({ current, prev }) => {
  const diff = ((current - prev) / prev) * 100;

  return (
    <SoftBadge
      pill
      bg={isNaN(diff) ? 'secondary' : diff > 0 ? 'success' : 'danger'}
      className="me-2 fs--2"
    >
      {!isNaN(diff) ? (
        <>
          {diff > 0 && '+'}
          {Math.round(diff * 1000) / 1000}%
        </>
      ) : (
        '-%'
      )}
    </SoftBadge>
  );
};
DiffBadge.propTypes = {
  current: PropTypes.number,
  prev: PropTypes.number
};
export default DiffBadge;
