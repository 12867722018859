import React, { useEffect, useMemo, useState } from 'react';
import { applicantsAPI } from 'apis/flex/recruitment';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  faFastForward,
  faStar,
  faThumbsDown,
  faThumbsUp
} from '@fortawesome/free-solid-svg-icons';
import { Button, Modal } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { getCommonDataValues } from 'helpers/utils';
import ApplicantEditModal from '../edit/ApplicantEditModal';
import MoveToStage from '../MoveToStage';
import { AcceptForm } from '../AcceptApplicant';
import { RejectForm } from '../RejectApplicant';
import { ResponsiveModal } from 'components/common/Modals';
import { useGuard } from 'hooks/useGuard';
import ScoreForm from '../ScoreForm';
import useCampaignApplicants from './useCampaignApplicants';
import { BottomBarAction } from 'components/common/detail/DetailPage';
import LoadingButton from 'components/common/LoadingButton';

type ActionModalProps = {
  title?: string;
  id?: number | number[];
  mutation?: any;
  show: boolean;
  setShow: any;
  form?: any;
  variant?: string;
  campaignId: number;
  defaultValues: any;
};
const ActionModal = ({
  title,
  id,
  mutation: mutationSetup,
  show,
  setShow,
  form: ModalForm,
  variant,
  campaignId,
  defaultValues
}: ActionModalProps) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    enabled: !!mutationSetup,
    ...mutationSetup,
    onSuccess: () => {
      queryClient.invalidateQueries(['Applicants']);
      queryClient.invalidateQueries(['CustomFormResponses']);
    }
  });
  const methods = useForm({ defaultValues });
  const handleConfirm = methods.handleSubmit(vals => {
    // console.log(vals);
    mutation.mutate(vals, {
      onSuccess: () => {
        setShow(null);
      }
    });
  }, console.error);
  useEffect(() => {
    methods.reset({ applicantIds: id || [], ...defaultValues });
  }, [id, defaultValues]);

  return (
    <ResponsiveModal wide show={show} onHide={() => setShow(null)}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <FormProvider {...methods}>
        <Modal.Body>
          {ModalForm && <ModalForm campaignId={campaignId} ids={id} />}
        </Modal.Body>
      </FormProvider>
      <Modal.Footer>
        <Button variant="link" onClick={() => setShow(null)}>
          Cancel
        </Button>
        <LoadingButton
          loading={mutation.isLoading}
          variant={variant || 'primary'}
          onClick={handleConfirm}
        >
          Confirm
        </LoadingButton>
      </Modal.Footer>
    </ResponsiveModal>
  );
};
type EditModalProps = {
  show: boolean;
  setShow: any;
  ids: number[];
  data: any;
  campaignId: number;
  formId: number;
};
const EditModal = ({
  show,
  setShow,
  ids,
  data,
  campaignId,
  formId
}: EditModalProps) => {
  return (
    <ApplicantEditModal
      campaignId={Number(campaignId)}
      show={show}
      setShow={setShow}
      ids={ids}
      data={data}
      formId={formId}
    />
  );
};
export default (campaignId: number, applicantIds?: number[]) => {
  //state
  const [actionModal, setActionModal] =
    useState<Omit<ActionModalProps, 'show' | 'setShow' | 'campaignId'>>();
  const [editModal, setEditModal] =
    useState<Omit<EditModalProps, 'show' | 'setShow' | 'campaignId'>>();
  const applicantIdArr = useMemo(
    () =>
      Array.isArray(applicantIds) || !applicantIds
        ? applicantIds
        : [applicantIds],
    [applicantIds]
  );
  //data fetch/sync
  const { data = [] } = useCampaignApplicants({
    campaignId,
    staleTime: Infinity
  });
  const queryClient = useQueryClient();
  const getData = rowIds => {
    const rowIdArr = Array.isArray(rowIds) || !rowIds ? rowIds : [rowIds];
    return data.filter(d =>
      (applicantIdArr ?? rowIdArr ?? []).some(a => a == d?.applicant.id)
    );
  };
  const getIds = ids => getData(ids).map(d => d.applicant.id);
  //mutations
  const invalidate = () => {
    console.log('invalidating applicants');
    queryClient.invalidateQueries(['CustomFormResponses']);
    return queryClient.invalidateQueries(['Applicants']);
  };
  const acceptMutation = {
    mutationKey: ['acceptApplicants'],
    mutationFn: ({ applicantIds, ...data }) => {
      return applicantsAPI.accept(applicantIds, data);
    },
    onSuccess: invalidate
  };
  const scoreMutation = {
    // mutationKey: ['scoreApplicants'],
    mutationFn: ({ applicantIds, score }) => {
      return applicantsAPI.score(applicantIds, score);
    },
    onSuccess: invalidate
  };
  const stageMutation = {
    mutationKey: ['applicantStages'],
    mutationFn: ({ applicantIds, ...rest }) => {
      return applicantsAPI.stage.move(applicantIds, rest);
    },
    onSuccess: invalidate
  };
  const { mutate: mutateScore } = useMutation<any, Error, any>({
    mutationFn: ({ applicantIds, score }) => {
      return applicantsAPI.score(applicantIds, score);
    },
    onSuccess: invalidate
  });
  const rejectMutation = {
    mutationFn: ({ applicantIds, feedback, notificationTemplateId }) =>
      applicantsAPI.reject(applicantIds, feedback, notificationTemplateId),
    onSuccess: invalidate
  };
  // const deleteMutation = {
  //   mutationFn: ({ applicantIds }) => applicantsAPI.delete(applicantIds),
  //   onSuccess: invalidate
  // };
  //handlers
  const handleAccept = ids =>
    setActionModal({
      title: 'Accept',
      id: ids,
      form: AcceptForm,
      mutation: acceptMutation,
      defaultValues: { prescreening: ['Right to work'] }
    });
  const handleReject = ids =>
    setActionModal({
      title: 'Reject',
      id: ids,
      form: RejectForm,
      mutation: rejectMutation,
      defaultValues: {}
    });
  const handleEdit = applicantIds => {
    const commonData = getCommonDataValues(
      getData(applicantIds).map(a => a.response.data.form)
    );
    setEditModal({
      ids: getData(applicantIds).map(d => d.applicant.id),
      data: commonData,
      formId: getData(applicantIds)[0].response.formId
    });
  };

  const handleStageMove = id =>
    setActionModal({
      title: 'Move stage',
      id,
      form: MoveToStage,
      mutation: stageMutation,
      defaultValues: {}
    });

  // const handleDelete = id =>
  //   setActionModal({
  //     title: 'Delete applicants',
  //     id,
  //     variant: 'danger',
  //     form: DeleteApplicants,
  //     mutation: deleteMutation,
  //     defaultValues: {}
  //   });
  const handleScore = (id, score?: number) => {
    if (score !== undefined) {
      return mutateScore({ applicantIds: id, score });
    }
    setActionModal({
      title: 'Evaluation score',
      id,
      form: ScoreForm,
      mutation: scoreMutation,
      defaultValues: {}
    });
  };
  const { canEdit } = useGuard({ roles: ['applicant'] });
  //returns
  const actions: BottomBarAction[] = [
    {
      label: 'Accept',
      variant: 'success',
      isPrimary: true,
      disabled: !canEdit,
      icon: faThumbsUp,
      show: ids => getData(ids).every(d => !d.applicant.acceptReject),
      fn: ids => handleAccept(getIds(ids))
    },
    {
      label: 'Reject',
      variant: 'danger',
      isPrimary: true,
      disabled: !canEdit,
      icon: faThumbsDown,
      fn: ids => handleReject(getIds(ids)),
      show: ids => getData(ids).every(d => !d.applicant.acceptReject)
    },
    {
      label: 'Add score',
      variant: 'info',
      disabled: !canEdit,
      icon: faStar,
      fn: ids => handleScore(getIds(ids))
      // show: ids =>
      //   getData(ids).every(
      //     d => !d.applicant.scores?.find(s => s.scoredBy === userId)
      //   )
    },
    {
      label: 'Move stage',
      icon: faFastForward,
      disabled: !canEdit,
      isPrimary: true,
      show: ids => getData(ids).every(d => !d.applicant.acceptReject),
      fn: ids => {
        console.log('handleStageMove', ids, getIds(ids));
        handleStageMove(getIds(ids));
      }
    },
    {
      label: 'Edit',
      icon: 'edit',
      disabled: !canEdit,
      show: () => true,
      fn: ids => handleEdit(getIds(ids))
    }
    // { label: 'Call', icon: 'phone-alt' },
    // {
    //   label: 'Delete',
    //   icon: 'trash-alt',
    //   variant: 'danger',
    //   show: () => true,
    //   disabled: !canDelete,
    //   fn: ids => handleDelete(getIds(ids))
    // }
  ];
  const returns =
    // useMemo(
    //   () => (
    {
      actions,
      handleAccept,
      handleReject,
      handleEdit,
      handleStageMove,
      // handleDelete,
      handleScore,
      ActionModal: () => (
        <ActionModal
          {...actionModal}
          campaignId={campaignId}
          show={!!actionModal}
          setShow={setActionModal}
        />
      ),
      EditModal: () => (
        <EditModal
          campaignId={campaignId}
          {...editModal}
          show={!!editModal}
          setShow={setEditModal}
        />
      )
    };
  // ),
  //   [actionModal, editModal, campaignId, applicantIds, userId, data]
  // );
  return returns;
};
