import {
  MutateOptions,
  QueryObserverOptions,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import { apiError, apiPromise, apiSuccess } from 'apis/errors';
import CrudApi from 'apis/flex/CrudApi';
import { useMemo } from 'react';
import useCrudConfig from './useCrudConfig';
import { ApiCustomFilters, ApiFilters } from 'apis/types';
export type FilterValue<TData> = {
  [P in keyof TData]: TData[P] | 'null' | TData[P][];
};
export type CrudFilters<TData> =
  | Partial<FilterValue<TData>>
  | ApiCustomFilters<TData>;
type SettableUseQueryOptions<TData, TSelected> = Omit<
  QueryObserverOptions<TData, Error, TSelected>,
  | 'select'
  | 'enabled'
  | 'queryFn'
  | 'queryKey'
  | 'staleTime'
  | 'noReturnOnChange'
  | 'placeholderData'
  | 'structuralSharing'
>;

export type CountBy<TData = any> = string & keyof TData;
type CrudReturn<TData, TBy extends CountBy<TData>[]> = {
  count: number;
} & {
  [K in TBy[number]]: K extends keyof TData ? TData[K] : never;
};
export type CrudCounts<TData, TBy extends CountBy<TData>[]> = CrudReturn<
  TData,
  TBy
>[];
export type UseCrudProps<TData, TSelected> = SettableUseQueryOptions<
  TData,
  TSelected
> & {
  id?: number | string;
  filters?: CrudFilters<TData>;
  select?: (data: TData[]) => TSelected;
  enabled?: boolean;
  staleTime?: number;
  useFilter?: boolean;
  afterSave?: (data: TData) => void;
  includeDeleted?: boolean;
  getPublic?: boolean;
  noReturnOnChange?: boolean;
  count?: boolean;
  countBy?: CountBy<TData>[];
  allowedOnly?: boolean;
  beforeSave?: (data: TData) => any;
};
export type DoneCallback<TData = any> = (data?: TData) => void;
export type ErrorCallback = (error?: Error) => void;
export const mutateAsyncToast = <T extends any[] = any[]>(
  fn: (...args: T) => Promise<any>
) => {
  return (...args: T) =>
    apiPromise(fn(...args), {
      pending: 'Working on it...',
      success: null,
      error: null
    });
};
const useDefaultCrud = <
  TData extends { id?: number; deletedBy?: number; deletedDate?: Date } = any,
  TSelected = TData[],
  TBy extends CountBy<TData>[] = CountBy<TData>[]
>(
  queryKey: string,
  api: CrudApi<any, any, TData>,
  {
    countBy,
    ...props
  }: Omit<UseCrudProps<TData, TSelected>, 'countBy'> & { countBy?: TBy } = {}
) => {
  const crudConfig = useCrudConfig() || {};
  const {
    id,
    filters,
    select,
    enabled = true,
    staleTime,
    useFilter = false,
    afterSave,
    includeDeleted,
    getPublic = false,
    noReturnOnChange,
    count,
    allowedOnly,
    beforeSave = d => d,
    initialData,
    ...rest
  }: UseCrudProps<any, any> = { ...props, ...crudConfig };
  const enableMemo = useMemo(
    () => !!((!!id || !!filters || useFilter) && enabled && !count && !countBy),
    [id, filters, useFilter, enabled]
  );
  const _queryKey = [
    queryKey,
    Number(id),
    filters,
    useFilter,
    getPublic,
    staleTime,
    includeDeleted,
    count,
    countBy,
    allowedOnly
  ];
  const query = useQuery<TData[], Error, TSelected>({
    queryKey: _queryKey,
    queryFn: () => {
      if (useFilter) {
        if (getPublic) {
          return api.getPublic(filters, includeDeleted);
        }
        return api.get(filters, includeDeleted);
      } else if (id) {
        if (getPublic) {
          return api.getPublic({ id } as ApiFilters<TData>, true);
        }
        return api.getOne(id);
      } else {
        return Promise.resolve([]);
      }
    },
    select,
    enabled: enableMemo,
    staleTime: enabled && (staleTime || 1000 * 10),
    initialData,
    ...rest
  });
  const countEnableMemo = useMemo(
    () => !!((!!filters || useFilter) && enabled && (count || countBy)),
    [id, filters, useFilter, enabled, count, countBy]
  );
  const { data: counts, isLoading: isCountsLoading } = useQuery<
    TData[],
    Error,
    CrudCounts<TData, TBy>
  >({
    queryKey: _queryKey,
    queryFn: () => {
      if (useFilter) {
        return api
          .counts<TBy>(countBy, filters, allowedOnly, includeDeleted)
          .then(d => d.map(c => ({ ...c, count: Number(c.count) })));
      } else {
        return Promise.resolve(null);
      }
    },
    enabled: countEnableMemo,
    staleTime: enabled && (staleTime || 1000 * 10),
    ...rest
  });
  // useEffect(() => {
  //   if (query.error) {
  //     apiError(query.error);
  //   }
  // }, [query.error]);
  const queryClient = useQueryClient();

  const {
    mutate: update,
    isLoading: isUpdating,
    error: updateError,
    mutateAsync: updateAsync
  } = useMutation<
    TData,
    Error,
    {
      id: number;
      data: Partial<Omit<TData, 'id'>>;
      isSelf?: boolean;
      noReturn?: boolean;
    },
    { previous: TData; updated: any }
  >({
    mutationFn: ({ id, data, isSelf, noReturn = noReturnOnChange }) =>
      isSelf
        ? api.selfUpdate(id, beforeSave(data), noReturn)
        : api.update(id, beforeSave(data), noReturn),
    onMutate: async ({ id, data, isSelf, noReturn }) => {
      const _queryKey = [
        queryKey,
        id,
        filters,
        useFilter,
        getPublic,
        staleTime
      ];
      await queryClient.cancelQueries({ queryKey: _queryKey });

      // Snapshot the previous value
      const previous = queryClient.getQueryData<TData>(_queryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(_queryKey, {
        id,
        data: beforeSave(data),
        isSelf,
        noReturn
      });

      // Return a context with the previous and new todo
      return { previous, updated: { id, data, isSelf, noReturn } };
    },
    onSuccess: data => {
      queryClient.invalidateQueries([queryKey]);
      if (afterSave) {
        return afterSave(data);
      }
      apiSuccess('Updated!');
    },
    onError: (err, data, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      queryClient.setQueryData(_queryKey, context.previous);
      apiError(err);
    }
  });

  const {
    mutate: bulkUpdate,
    isLoading: isBulkUpdating,
    error: bulkUpdateError,
    mutateAsync: bulkUpdateAsync
  } = useMutation<
    TData,
    Error,
    {
      ids: number[];
      data: Partial<Omit<TData, 'id'>>;
      isSelf?: boolean;
      noReturn?: boolean;
    },
    { previous: TData; updated: any }
  >({
    mutationFn: ({ ids, data, noReturn = noReturnOnChange }) =>
      api.updateBulk({ id: ids }, beforeSave(data), noReturn),
    onMutate: async ({ data, isSelf, noReturn }) => {
      const _queryKey = [
        queryKey,
        id,
        filters,
        useFilter,
        getPublic,
        staleTime
      ];
      await queryClient.cancelQueries({ queryKey: _queryKey });

      // Snapshot the previous value
      const previous = queryClient.getQueryData<TData>(_queryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(_queryKey, {
        id,
        data: beforeSave(data),
        isSelf,
        noReturn
      });

      // Return a context with the previous and new todo
      return { previous, updated: { id, data, isSelf, noReturn } };
    },
    onSuccess: data => {
      queryClient.invalidateQueries([queryKey]);
      if (afterSave) {
        return afterSave(data);
      }
      apiSuccess('Updated!');
    },
    onError: (err, data, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      queryClient.setQueryData(_queryKey, context.previous);
      apiError(err);
    }
  });

  const {
    mutate: add,
    isLoading: isAdding,
    error: addError,
    mutateAsync: addAsync
  } = useMutation<
    TData,
    Error,
    { vals: Omit<TData, 'id'>; isSelf?: boolean; noReturn?: boolean }
  >({
    mutationFn: ({ vals, isSelf, noReturn = noReturnOnChange }) =>
      isSelf
        ? api.selfInsert(beforeSave(vals), noReturn)
        : api.insert(beforeSave(vals), noReturn),
    onSuccess: data => {
      queryClient.invalidateQueries([queryKey]);
      if (afterSave) {
        return afterSave(data);
      }
      apiSuccess('Created!');
    }
  });
  const {
    mutate: clone,
    isLoading: isCloning,
    error: cloneError,
    mutateAsync: cloneAsync
  } = useMutation<
    TData,
    Error,
    { id: number; isSelf?: boolean; noReturn?: boolean }
  >({
    mutationFn: ({ id, noReturn = noReturnOnChange }) =>
      api.clone(id, noReturn),
    onSuccess: data => {
      queryClient.invalidateQueries([queryKey]);
      if (afterSave) {
        return afterSave(data);
      }
      apiSuccess('Created!');
      queryClient.refetchQueries(_queryKey);
    }
  });

  const {
    mutate: remove,
    isLoading: isRemoving,
    error: removeError,
    mutateAsync: removeAsync
  } = useMutation<TData, Error, number>({
    mutationFn: id => api.remove(id),
    onSuccess: data => {
      queryClient.invalidateQueries([queryKey]);
      if (afterSave) {
        return afterSave(data);
      }
      apiSuccess('Removed!');
      queryClient.refetchQueries(_queryKey);
    }
  });

  const {
    mutate: bulkRemove,
    isLoading: isBulkRemoving,
    error: bulkRemoveError,
    mutateAsync: bulkRemoveAsync
  } = useMutation<TData, Error, number[]>({
    mutationFn: ids => api.bulkRemove(ids),
    onSuccess: data => {
      queryClient.invalidateQueries([queryKey]);
      if (afterSave) {
        return afterSave(data);
      }
      apiSuccess('Removed!');
      queryClient.refetchQueries(_queryKey);
    }
  });

  const {
    mutate: bulkAdd,
    isLoading: isBulkAdding,
    error: bulkAddError,
    mutateAsync: bulkAddAsync
  } = useMutation<
    TData[],
    Error,
    { vals: Omit<TData, 'id'>[]; noReturn: boolean }
  >({
    mutationFn: ({ vals, noReturn = noReturnOnChange }) =>
      api.insertBulk(beforeSave(vals), noReturn),
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey]);
      apiSuccess('Created!');
    }
  });
  const upsert =
    (async: boolean) =>
    (
      vals: TData,
      done: (data: TData) => void = () => {},
      isSelf?: boolean,
      noReturn?: boolean
    ) => {
      if (vals.id) {
        if (async) {
          return updateAsync({ id: vals.id, data: vals, isSelf, noReturn });
        }
        update(
          { id: vals.id, data: vals, isSelf, noReturn },
          {
            onSuccess: d => done(d)
          }
        );
      } else {
        if (async) {
          return addAsync({ vals, isSelf, noReturn });
        }
        add(
          { vals, isSelf, noReturn },
          {
            onSuccess: d => done(d)
          }
        );
      }
    };
  const interceptors = {
    updateSelf:
      (async?: boolean) =>
      (
        vals: { data: TData; id: number; noReturn?: boolean },
        opts?: MutateOptions<
          TData,
          Error,
          {
            id: number;
            data: Partial<Omit<TData, 'id'>>;
            isSelf?: boolean;
            noReturn?: boolean;
          },
          { previous: TData; updated: any }
        >
      ) =>
        async === true
          ? updateAsync({ ...vals, isSelf: true }, opts)
          : update({ ...vals, isSelf: true }, opts),
    add:
      (async?: boolean) =>
      (
        vals: Omit<TData, 'id'>,
        opts?: MutateOptions<
          TData,
          Error,
          { vals: Omit<TData, 'id'>; isSelf?: boolean; noReturn?: boolean }
        >,
        noReturn?: boolean
      ) =>
        async
          ? addAsync({ vals, isSelf: false, noReturn }, opts)
          : add({ vals, isSelf: false, noReturn }, opts),
    clone:
      (async?: boolean) =>
      (
        id: number,
        opts?: MutateOptions<
          TData,
          Error,
          { id: number; isSelf?: boolean; noReturn?: boolean }
        >,
        noReturn?: boolean
      ) =>
        async
          ? cloneAsync({ id, isSelf: false, noReturn }, opts)
          : clone({ id, isSelf: false, noReturn }, opts),
    addSelf:
      (async?: boolean) =>
      (
        vals: Omit<TData, 'id'>,
        opts?: MutateOptions<
          TData,
          Error,
          { vals: Omit<TData, 'id'>; isSelf?: boolean; noReturn?: boolean }
        >,
        noReturn?: boolean
      ) =>
        async
          ? addAsync({ vals, isSelf: true, noReturn }, opts)
          : add({ vals, isSelf: true, noReturn }, opts),
    upsertSelf:
      (async?: boolean) =>
      (vals: TData, done: (data: TData) => void, noReturn?: boolean) =>
        upsert(async)({ ...vals }, done, true, noReturn),
    bulkAdd:
      (async?: boolean) =>
      (
        vals: Omit<TData, 'id'>[],
        opts?: MutateOptions<
          TData[],
          Error,
          { vals: Omit<TData, 'id'>[]; noReturn: boolean }
        >,
        noReturn?: boolean
      ) =>
        async
          ? bulkAddAsync({ vals, noReturn }, opts)
          : bulkAdd({ vals, noReturn }, opts)
  };
  return {
    ...query,
    counts,
    error:
      query.error ||
      addError ||
      cloneError ||
      removeError ||
      bulkAddError ||
      updateError ||
      bulkUpdateError ||
      bulkRemoveError,
    isLoading:
      (enableMemo && query.isLoading) || (countEnableMemo && isCountsLoading),
    remove,
    isRemoving,
    update,
    upsert: upsert(false),
    bulkUpdate,
    isBulkUpdating,
    isUpdating,
    isAdding,
    isCloning,
    isUpserting: isUpdating || isAdding,
    isBulkAdding,
    isBulkRemoving,
    // ...interceptors,
    add: interceptors.add(false),
    addSelf: interceptors.addSelf(false),
    updateSelf: interceptors.updateSelf(false),
    clone: interceptors.clone(false),
    bulkAdd: interceptors.bulkAdd(false),
    upsertSelf: interceptors.upsertSelf(false),
    addAsync: mutateAsyncToast(interceptors.add(true) as any),
    addSelfAsync: mutateAsyncToast(interceptors.addSelf(true) as any),
    updateSelfAsync: mutateAsyncToast(interceptors.updateSelf(true) as any),
    cloneAsync: mutateAsyncToast(interceptors.clone(true) as any),
    bulkAddAsync: mutateAsyncToast(interceptors.bulkAdd(true) as any),
    upsertSelfAsync: mutateAsyncToast(interceptors.upsertSelf(true) as any),
    removeAsync: mutateAsyncToast(removeAsync),
    updateAsync: mutateAsyncToast(updateAsync),
    bulkUpdateAsync: mutateAsyncToast(bulkUpdateAsync),
    upsertAsync: mutateAsyncToast(upsert(true)),
    bulkRemove,
    bulkRemoveAsync: mutateAsyncToast(bulkRemoveAsync)
  };
};
export type UseDefaultCrudReturn<TData = any> = ReturnType<
  typeof useDefaultCrud<TData>
>;
export const defaultCrudHookBuilder =
  <TData extends { id?: number; deletedBy?: number; deletedDate?: Date } = any>(
    key: string,
    api: CrudApi<any, any, TData>,
    defaultProps?: UseCrudProps<TData, any>
  ) =>
  <TSelected = TData[], TBy extends CountBy<TData>[] = CountBy<TData>[]>({
    countBy,
    ...props
  }: Omit<UseCrudProps<TData, TSelected>, 'countBy'> & {
    countBy?: TBy;
  } = {}) => {
    const builder = createBuilder<TData, TSelected, TBy>(key, api, {
      ...defaultProps,
      ...props
    });
    return builder(countBy);
  };
const createBuilder = <
  TData extends { id?: number; deletedBy?: number; deletedDate?: Date },
  TSelected,
  TBy extends CountBy<TData>[]
>(
  key,
  api,
  props
) => {
  return (countBy: TBy) => {
    return useDefaultCrud<TData, TSelected, TBy>(key, api, {
      ...props,
      countBy
    });
  };
};
export default useDefaultCrud;
