import React from 'react';
import useClients from './useClients';
import { UseCrudProps } from 'hooks/useDefaultCrud';
import { Client } from 'apis/flex/projects';
import DomainTable from 'components/common/DomainTable';

export default (props: UseCrudProps<Client, Client[]>) => {
  const crudHook = useClients({
    useFilter: true,
    ...props
  });
  return (
    <DomainTable
      crudHook={crudHook}
      domain="client"
      columns={[
        'name',
        'website',
        'telephone',
        'email',
        'accountsEmail',
        { id: 'contacts', formatter: v => v()?.length }
      ]}
      editFields={['name']}
    />
  );
};
