import { debounce, isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

export default <T,>({
  data: inputData,
  ms = 1000,
  children
}: {
  data: T;
  ms?: number;
  children: (d: T) => React.ReactNode;
}) => {
  const { data } = useDebounce({ ms, data: inputData });
  return useMemo(() => children(data), [data]);
};
export const useDebounce = <T,>({ ms, data }: { ms?: number; data: T }) => {
  const [newData, setNewData] = useState<string>();
  const updateData = useCallback(
    debounce(data => {
      // console.log('running debounced fn');
      setNewData(data);
    }, ms),
    [ms]
  );
  const handleDataChange = d => {
    const stringifiedData = d ? JSON.stringify(d) : 'undefined';
    // console.log('data changed', stringifiedData, newData);
    if (!isEqual(stringifiedData, newData)) {
      updateData.cancel();
      //   console.log('starting debounce');
      updateData(stringifiedData);
    }
  };
  useEffect(() => {
    handleDataChange(data);
  }, [data]);
  return {
    setData: handleDataChange,
    data: useMemo<T>(() => newData && JSON.parse(newData), [newData])
  };
};
