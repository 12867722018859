import { resolveShortLink, ShortLink, shortLinksApi } from 'apis/flex/helpers';
import useDefaultCrud, { UseCrudProps } from './useDefaultCrud';
import { useQuery } from '@tanstack/react-query';

export default <T = ShortLink>(props?: UseCrudProps<ShortLink, T>) => {
  return useDefaultCrud<ShortLink, T>('ShortLinks', shortLinksApi, props);
};
export const useResolveShortLink = hash => {
  return useQuery<string, Error, string>({
    queryKey: ['resolveShortLink', hash],
    queryFn: () => resolveShortLink(hash),
    enabled: !!hash
  });
};
