import React from 'react';
import { Card } from 'react-bootstrap';
import FalconCardHeader, { FalconCardHeaderProps } from '../FalconCardHeader';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import RenderWhenVisible from '../RenderWhenVisible';
import { AnimatePresence, motion } from 'framer-motion';
import { uniqueId } from 'lodash';
import styled from 'styled-components';
import { useDetailPage } from './DetailPage';

const CardPlaceholder = () => (
  <>
    <Skeleton className="mb-2 w-25" />
    <Skeleton className="mb-3 w-75" height={38} />
    <Skeleton className="mb-2 w-25" />
    <Skeleton className="mb-3 w-75" height={38} />
  </>
);
export const ZCol = styled.div`
  :hover {
    z-index: 2;
  }
`;
const DetailCard = ({
  title = null,
  isLoading = false,
  children,
  isLast = false,
  buttons = null,
  subtitle = null,
  colProps = null,
  className = null,
  headerProps,
  id,
  animate = true,
  ...rest
}: {
  title?: React.ReactNode;
  isLoading?: boolean;
  children: React.ReactNode;
  isLast?: boolean;
  buttons?: React.ReactNode;
  subtitle?: React.ReactNode;
  colProps?: any;
  className?: string;
  headerProps?: Omit<FalconCardHeaderProps, 'title' | 'subtitle' | 'className'>;
  id: string;
  animate?: boolean;
}) => {
  const { isLoading: pageLoading } = useDetailPage() || {};
  return (
    <ZCol
      {...colProps}
      className={classNames('col-xs-12', colProps?.className)}
    >
      <AnimatePresence>
        <motion.div
          id={id || uniqueId('detail-card')}
          initial={!!animate && { x: '-100%' }}
          animate={{ x: '0%' }}
          exit={!!animate && { x: '-100%' }}
        >
          <Card
            {...rest}
            className={classNames({ 'mb-3': !isLast }, className)}
          >
            {title && (
              <FalconCardHeader
                className="sticky-top z-2 top-navbar-height"
                subtitle={subtitle}
                title={title}
                {...headerProps}
              />
            )}
            <Card.Body>
              <RenderWhenVisible placeholder={<CardPlaceholder />}>
                {isLoading || pageLoading ? <CardPlaceholder /> : children}
              </RenderWhenVisible>
            </Card.Body>
            {buttons && (
              <Card.Footer className="text-end">{buttons}</Card.Footer>
            )}
          </Card>
        </motion.div>
      </AnimatePresence>
    </ZCol>
  );
};
export default DetailCard;
