import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faChartLine,
  faClock,
  faComment,
  faDesktop,
  faEnvelope,
  faFileContract,
  faFileMedical,
  faFileUpload,
  faFrown,
  faHeadset,
  faLaptop,
  faMicrophone,
  faMicrophoneAlt,
  faParagraph,
  faPen,
  faPhone,
  faQuestion,
  faSmile,
  faStar,
  faSuitcase,
  faUser,
  faUserGraduate,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import { WizardInputComponent } from '../WizardInput';
import WizardSelect from './WizardSelect';
export const iconWizardIcons = [
  faChartLine,
  faSuitcase,
  faFileContract,
  faUser,
  faClock,
  faEnvelope,
  faCalendarAlt,
  faStar,
  faPhone,
  faUsers,
  faUserGraduate,
  faHeadset,
  faQuestion,
  faPen,
  faMicrophone,
  faMicrophoneAlt,
  faFileMedical,
  faDesktop,
  faLaptop,
  faComment,
  faSmile,
  faFrown,
  faFileUpload,
  faParagraph
];
const getIcon = key => ({
  prefix: key.split(' ')[0],
  iconName: key.split(' ')[1]
});
export const getRenderIconFromValue = value => {
  return value
    ? iconWizardIcons.find(i =>
        typeof value === 'string'
          ? i.prefix === value.split(' ')[0] &&
            i.iconName === value.split(' ')[1]
          : i.prefix === value.prefix && i.iconName === value.iconName
      ) || faQuestion
    : faQuestion;
};
const iconRender = ({ value }) => {
  return <FontAwesomeIcon key={value} icon={getRenderIconFromValue(value)} />;
};
const WizardIcon: WizardInputComponent = ({
  multiple,
  renderProps,
  formControlProps,
  pluginProps
}) => {
  const getValue = (
    v:
      | {
          prefix: string;
          iconName: string;
        }
      | { prefix: string; iconName: string }[]
  ) => {
    let vArr: null | { prefix: string; iconName: string }[];
    if (Array.isArray(v)) {
      vArr = v;
    } else if (!v) {
      vArr = null;
    } else {
      vArr = [v];
    }
    return vArr?.map(i => i.prefix + ' ' + i.iconName).join(' ') || [];
  };
  const setValue = (v: string[] | string) => {
    if (Array.isArray(v)) {
      return v.map(i => getIcon(i));
    } else {
      return getIcon(v);
    }
  };
  return (
    <WizardSelect
      renderProps={{
        ...renderProps,
        form: {
          ...renderProps.form,
          field: {
            ...renderProps.form.field,
            value: getValue(renderProps.form.field.value),
            onChange: v => renderProps.form.field.onChange(setValue(v))
          }
        }
      }}
      formControlProps={formControlProps}
      options={iconWizardIcons.map(i => ({
        value: i.prefix + ' ' + i.iconName,
        label: i.iconName
      }))}
      multiple={multiple}
      placeholder="Pick an icon"
      pluginProps={{
        grid: { cols: 4, width: 350 },
        formatOptionLabel: iconRender,
        styles: {
          option: {
            padding: '3px',
            fontSize: '1.728rem !important'
          }
        },
        ...pluginProps
      }}
    />
  );
};
export default WizardIcon;
