import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiError } from 'apis/errors';
import {
  customFormsApi,
  customFormsResponseApi,
  Form,
  FormResponse,
  FormResponseInput,
  FormResponseRecord,
  getSystemForm
} from 'apis/flex/customForms';
import { useCallback } from 'react';
export type CustomFormSubmit = (
  data: any,
  screenoutQuestionIds: any[],
  done?: (response: FormResponseRecord) => void,
  err?: (err: Error) => void
) => void;
export default ({
  formId,
  systemTag,
  meta,
  enabled = true,
  ...rest
}: {
  formId?: number;
  systemTag?: string;
  meta?: Record<string, any>;
  enabled?: boolean;
  staleTime?: number;
}) => {
  const { data: form, isLoading } = useQuery<Form>({
    queryFn: () => {
      if (systemTag) {
        return getSystemForm(systemTag);
      }
      if (!formId) {
        return Promise.resolve(null);
      }
      return customFormsApi.getOne(formId).then(d => d[0]);
    },
    queryKey: ['CustomForms', Number(formId), systemTag],
    staleTime: enabled ? Infinity : undefined,
    enabled: (!!formId || !!systemTag) && !!enabled,
    ...rest
  });
  const queryClient = useQueryClient();
  const { mutate } = useMutation<FormResponse, Error, FormResponseInput>({
    mutationFn: ({ data, formId, screenoutQuestionIds, metadata }) =>
      customFormsResponseApi.insert(
        {
          formId,
          metadata,
          data,
          screenoutQuestionIds
        },
        true
      ),
    mutationKey: ['CustomFormSubmit', formId || systemTag],
    onSuccess: (d, { formId }) => {
      queryClient.invalidateQueries(['CustomFormResponses', formId]);
    }
  });

  const handleSubmit: CustomFormSubmit = useCallback(
    (data, screenoutQuestionIds, done, err) => {
      //remove any form values which are not question Ids for the loaded form
      const formQids = form.sections?.flatMap(s =>
        s.questions?.map(q => q.id?.toString())
      );
      const vals = Object.keys(data)
        .filter(k => formQids.includes(k.toString()))
        .reduce((a, b) => ({ ...a, [b]: data[b] }), {});
      mutate(
        {
          data: vals,
          screenoutQuestionIds: screenoutQuestionIds?.length
            ? screenoutQuestionIds.map(id => Number(id))
            : null,
          formId: form.id,
          metadata: meta
        },
        {
          onSuccess: data => {
            return done && done(data);
          },
          onError: e => {
            if (err) {
              return err(e);
            }
            apiError(e);
          }
        }
      );
    },
    [mutate, form]
  );
  return {
    form,
    isLoading,
    handleSubmit
  };
};
