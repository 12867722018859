import { getDomainItemEvents, SavedEvent } from 'apis/flex/helpers';
import { useInfiniteQuery } from '@tanstack/react-query';
import { ApiCustomFilters, ApiFilters } from 'apis/types';

export default ({
  domain,
  itemId,
  actions = ['added', 'changed', 'removed', 'loggedin'],
  authorIds,
  pageSize = 10,
  enabled = true,
  custom
}: {
  domain?: string;
  itemId?: string;
  actions?: string[];
  authorIds?: number[];
  pageSize?: number;
  enabled?: boolean;
  custom?: ApiCustomFilters<SavedEvent>;
}) => {
  return useInfiniteQuery({
    queryKey: ['DomainEventsPaged', domain, itemId, actions, authorIds, custom],
    enabled,
    queryFn: ({ pageParam = 1 }) =>
      getDomainItemEvents(
        { domain, itemId, actions: actions, authorIds, customFilters: custom },
        { page: pageParam, pageSize }
      ),
    staleTime: 1000 * 30,
    getNextPageParam: (lastPage, allPages) =>
      lastPage.length === 10 ? allPages.length + 1 : undefined
  });
};
