import React from 'react';
import WizardInput from 'components/wizard/WizardInput';
import PropTypes from 'prop-types';
const ScoreForm = ({ score, ...rest }) => {
  return (
    <WizardInput
      type="rating"
      registerProps={{ readOnly: !!score }}
      pluginProps={{ max: 5, rating: score, ...rest }}
      name="score"
    />
  );
};
ScoreForm.propTypes = {
  score: PropTypes.number
};
export default ScoreForm;
