import React, { ReactNode } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { transitions } from 'helpers/animations';
import Flex from './Flex';
import classNames from 'classnames';
import DomainAvatar from './DomainAvatar';
import DomainIcon from './DomainIcon';
import { useNavigate } from 'react-router-dom';
import { FiMoreHorizontal } from 'react-icons/fi';
import { EventPrefix } from 'apis/flex/notifications';
const IconItem = ({
  data = {},
  compact,
  show,
  isLoading,
  domain,
  size = 'md'
}: {
  data: {
    avatar?: string;
    info?: Record<string, ReactNode>;
    label?: string;
    value?: number;
    icon?: ReactNode;
    color?: string;
  };
  compact?: boolean;
  show?: boolean;
  isLoading?: boolean;
  domain: EventPrefix;
  size?: 'sm' | 'md' | 'lg';
}) => {
  const nav = useNavigate();
  const onNameClick = () => domain && nav(`/domain/${domain}/${data.value}`);
  return (
    <Flex
      className={classNames(
        'align-items-center position-relative d-flex mb-2 text-start'
      )}
    >
      {data.avatar ? (
        <DomainAvatar
          size={size}
          domain={domain}
          src={data.avatar}
          isLoading={isLoading}
        />
      ) : (
        <DomainIcon
          icon={data.icon}
          color={data.color}
          size={size}
          domain={domain}
        />
      )}
      {!compact && (
        <motion.div
          className="overflow-hidden text-nowrap ms-1"
          variants={{ hover: { maxWidth: '100%' } }}
          initial={show ? { maxWidth: '100%' } : { maxWidth: 0 }}
        >
          <h6
            className={classNames('fw-normal mb-0', {
              'btn-link': !!onNameClick
            })}
            onClick={() => onNameClick && onNameClick()}
          >
            {data.label}
          </h6>
          {data.info && (
            <motion.div
              transition={transitions.lightBounce}
              className="overflow-hidden text-nowrap d-block"
              variants={{
                hover: { height: 12 * Object.keys(data.info).length }
              }}
              initial={{ height: 0 }}
            >
              {Object.keys(data.info)
                .filter(name => !!data.info[name])
                .filter((_, i) => i < 4)
                .map(name => (
                  <div
                    key={name}
                    className="fs--2 lh-1 mb-0 text-500 d-flex align-items-center"
                  >
                    {data.info[name]}
                  </div>
                ))}
            </motion.div>
          )}
        </motion.div>
      )}
    </Flex>
  );
};
export type DomainIconItemProps = {
  data: {
    avatar?: string;
    info?: Record<string, ReactNode>;
    label?: string;
    icon?: ReactNode;
    color?: string;
  }[];
  domain: EventPrefix;
  size?: 'sm' | 'md' | 'lg';
  placeholder?: ReactNode;
  compact?: boolean;
  maxShown?: number;
} & Omit<
  React.HTMLAttributes<HTMLDivElement>,
  'onAnimationStart' | 'onDragStart' | 'onDragEnd' | 'onDrag'
>;
const DomainItemIcon = ({
  data = [],
  domain,
  size = 'md',
  placeholder = 'N/A',
  className,
  compact,
  maxShown = 10,
  ...rest
}: DomainIconItemProps) => {
  const widths = {
    sm: 32,
    md: 56,
    lg: 98
  };
  const avatarWidth = widths[size];
  const rowHeight = avatarWidth;
  const compactGap = 10;
  const toShow = data.slice(0, maxShown);
  const unShown = data.slice(maxShown);
  if (unShown.length) {
    toShow.push({
      label: unShown.length + ' more',
      icon: <FiMoreHorizontal />,
      color: 'secondary'
    });
  }
  const parent = {
    hover: {
      height: toShow.length * rowHeight,
      width: '100%'
      // transition: {
      // when: 'beforeChildren',
      // staggerChildren: 0.1
      // }
    },
    initial: {
      height: rowHeight,
      width: compactGap * toShow.length + avatarWidth,
      transition: {
        when: 'afterChildren'
      }
    }
  };
  const children = {
    hover: i => ({
      x: 0,
      y: rowHeight * i,
      // width: '100%',
      transition: {
        when: false as const
      }
    }),
    initial: i => ({
      opacity: 1,
      x: compactGap * i,
      // width: avatarWidth,
      transition: {
        when: false as const
      }
    })
  };
  return (
    <>
      <AnimatePresence>
        {!toShow?.length ? (
          <div className="text-500">{placeholder}</div>
        ) : (
          <motion.div
            className={classNames(
              'position-relative',
              { 'my-2': !className },
              className
            )}
            variants={parent}
            initial={'initial'}
            whileHover={'hover'}
            transition={transitions.lightBounce}
            {...rest}
          >
            {toShow.map((row, i) => {
              return (
                <motion.div
                  className="position-absolute"
                  variants={children}
                  custom={i}
                  key={i}
                  style={{ zIndex: i + 1 }}
                  // initial={{ opacity: 0, x: '100px' }}
                  animate={{
                    opacity: 1,
                    x: compactGap * i,
                    // width: avatarWidth,
                    transition: {
                      when: false
                    }
                  }}
                  // transition={transitions.lightBounceStagger(i)}
                >
                  <IconItem
                    key={i}
                    domain={domain}
                    data={row}
                    show={toShow.length === 1}
                    size={size}
                    compact={compact}
                  />
                </motion.div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
export default DomainItemIcon;
