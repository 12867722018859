import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getMedia, getMediaStream } from 'apis/flex/helpers';
import { FileId } from 'apis/types';
import { blobIcon } from 'helpers/utils';
import useArray from 'hooks/useArray';
import is from 'is_js';
import { groupBy } from 'lodash';
import { useMemo } from 'react';
type FileValues = {
  id?: FileId;
  type: string;
  name: string;
  path?: string;
  uploading?: boolean;
  uploadPath: string;
  uploaded?: boolean;
  icon: IconProp;
  preview: string;
  size: number;
};
export type LoadedFile = FileValues;

//Old implementation
// const _useFileLoader = value => {
//   const [toFetch, setToFetch] = useState([]);
//   const [fetching, setFetching] = useState([]);
//   const [loaded, setLoaded] = useState<Record<string, FileValues>>({});
//   const processingHasNotBegun = val =>
//     !fetching.includes(val) && !toFetch.includes(val) && !loaded[val];
//   useEffect(() => {
//     if (value && is.array(value)) {
//       const toAdd = [];
//       value.forEach(val => {
//         if (is.string(val) && processingHasNotBegun(val)) {
//           toAdd.push(val);
//         }
//         //check if value is a false file object, e.g. a form value loaded from cache or otherwise serialised
//         if (
//           is.object(val) &&
//           val.uploadPath &&
//           val.webkitRelativePath === undefined &&
//           processingHasNotBegun(val.uploadPath)
//         ) {
//           toAdd.push(val.uploadPath);
//         }
//       });
//       setToFetch(f => [...f, ...toAdd]);
//       if (
//         value.filter(v => v.name || v.path).length !==
//         files.filter(v => v.name || v.path).length
//       ) {
//         setFiles(mapFiles(loaded, value));
//       }
//     }
//   }, [value]);
//   const [files, setFiles] = useState([]);
//   const { mutate } = useMutation<
//     { mimeType: string; name: string; size: number },
//     Error,
//     { id: FileId }
//   >({
//     mutationFn: ({ id }) =>
//       // getMedia(typeof id === 'string' && id.replace(/\.[^/.]+$/, '')),
//       getMedia(id),
//     onSuccess: async (resp, { id }) => {
//       const stream = await fetchWithCredentials(getMediaStreamUrl(id));
//       const url = URL.createObjectURL(await stream.blob());
//       const fileValue = {
//         type: resp.mimeType,
//         name: resp.name,
//         uploadPath: id,
//         uploaded: true,
//         icon: blobIcon({ type: resp.mimeType }),
//         preview: url,
//         size: resp.size
//       };
//       setLoaded(l => ({ ...l, [id]: fileValue }));
//     },
//     onError: (err, { id }) => {
//       console.error(err);
//       setLoaded(l => ({ ...l, [id]: { ...l[id], uploadError: err } }));
//     }
//   });
//   const mapFiles = useCallback((loaded, value) => {
//     // console.log(loaded, value);
//     if (!value) {
//       console.log('No value, returning blank array');
//     }
//     // if (is.array(value)) {
//     //   console.log('value is an array');
//     //   console.log(
//     //     'mapped value is ',
//     //     value.map(f => (is.string(f) && (loaded[f] || f)) || f)
//     //   );
//     // } else {
//     //   if (is.string(value)) {
//     //     console.log('will return as still loading, as value is a string', [
//     //       value
//     //     ]);
//     //   } else {
//     //     console.log('will return loaded. Value is a single file. Returning ', [
//     //       value
//     //     ]);
//     //   }
//     // }
//     return !value
//       ? []
//       : is.array(value)
//       ? value.map(f => (is.string(f) && (loaded[f] || f)) || f)
//       : [value];
//   }, []);
//   useEffect(() => {
//     toFetch.forEach(v => {
//       if (is.string(v) && !fetching.includes(v)) {
//         setFetching([...fetching, v]);
//         mutate({ id: v });
//       }
//     });
//   }, [toFetch]);
//   useEffect(() => {
//     setFiles(mapFiles(loaded, value));
//   }, [loaded]);
//   return useMemo<{
//     files: LoadedFile[];
//     reset: () => void;
//     isLoading: boolean;
//   }>(
//     () => ({
//       files: mapFiles(loaded, value),
//       reset: () => setLoaded({}),
//       isLoading:
//         is.array(value) && mapFiles(loaded, value).some(e => is.string(e))
//     }),
//     [mapFiles, loaded, value]
//   );
// };

/**
 * To be used when full file information, e.g. name, size, as well as stream is needed
 * If only need stream, use useMediaBlob. If only need info, use useMediaInfo
 */
export default ({
  value
}: {
  value: (FileId | LoadedFile | string)[] | FileId | LoadedFile | string;
}) => {
  const fileIds = useArray(value);
  // console.log('values to load', value);
  const shouldBeFetched = groupBy(
    fileIds,
    v =>
      (typeof v === 'string' &&
        !v.startsWith('http') &&
        !v.startsWith('blob')) ||
      (typeof v !== 'string' &&
        is.object(v) &&
        'uploadPath' in v &&
        //check if value is a false file object, e.g. a form value loaded from cache or otherwise serialised
        !('webkitRelativePath' in v) &&
        !(v.uploadPath || v.preview).startsWith('http') &&
        !(v.uploadPath || v.preview).startsWith('blob'))
  );
  const toFetch =
    shouldBeFetched.true?.map(
      v => (typeof v === 'string' ? v : v.uploadPath || v.preview) as FileId
    ) || [];
  const files = (shouldBeFetched.false || []) as LoadedFile[];
  const queryClient = useQueryClient();
  const { data: fetched, isLoading } = useQuery<LoadedFile[], Error>({
    queryKey: ['fileLoader', ...toFetch],
    queryFn: () => {
      const files = Promise.all(
        toFetch.map(async id => {
          const alreadyFetched = queryClient.getQueryData<LoadedFile>([
            'fileLoader_inner',
            id
          ]);
          // console.log(id, JSON.stringify(alreadyFetched));
          if (alreadyFetched) return alreadyFetched;
          const [info, blob] = await Promise.all([
            getMedia(id),
            getMediaStream(id)
          ]);
          const url = URL.createObjectURL(blob);
          const file: LoadedFile = {
            type: info.mimeType,
            name: info.name,
            uploadPath: id,
            uploaded: true,
            icon: blobIcon({ type: info.mimeType }),
            preview: url,
            size: info.size
          };
          // console.log(
          //   'setting fileLoader query data',
          //   ['fileLoader', id],
          //   file
          // );
          queryClient.setQueryData(['fileLoader_inner', id], file);
          return file;
        })
      );
      return files;
    }
  });
  const memo = useMemo(() => {
    return [...(files || []), ...(fetched || [])];
  }, [files, fetched]);
  return { isLoading, files: memo };
};
