import { useQuery } from '@tanstack/react-query';
import { ApplicantResponse } from 'apis/flex/recruitment';
import { useMemo } from 'react';
import useApplicants from './useApplicants';

export default <TSelected extends any[] = ApplicantResponse[]>(
  {
    campaignId,
    select = d => d as TSelected,
    ...rest
  }: {
    campaignId: number;
    select?: (data: ApplicantResponse[]) => TSelected;
    staleTime?: number;
  } = { campaignId: 0 }
) => {
  const enabled = useMemo(() => !!campaignId, [campaignId]);
  const { data, isLoading, error } = useApplicants<TSelected>({
    filters: { campaignId: Number(campaignId) },
    enabled,
    select,
    ...rest
  });
  return { data, isLoading, error };
};
