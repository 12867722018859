import React from 'react';
import { Button, Card } from 'react-bootstrap';
import LoadingButton from '../LoadingButton';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import FalconCardHeader from '../FalconCardHeader';
import { AnimatePresence, motion } from 'framer-motion';

const DetailSidebar = ({
  title = 'Settings',
  children,
  onSave,
  isSaving,
  isLoading,
  onCancel
}) => {
  return (
    <AnimatePresence>
      <motion.div
        id="sidebar"
        className="sticky-sidebar"
        initial={{ x: '100%' }}
        exit={{ x: '100%' }}
        whileInView={{ x: '0%' }}
        animate={{ x: '0%' }}
      >
        {isLoading ? (
          <Card>
            <Card.Body>
              <Skeleton count={2} />
            </Card.Body>
          </Card>
        ) : !onCancel && !onSave ? (
          children
        ) : (
          <Card>
            <FalconCardHeader title={title} />
            <Card.Body className="bg-light">{children}</Card.Body>
            <Card.Footer className="text-end">
              <Button variant="link" onClick={onCancel}>
                Cancel
              </Button>
              <LoadingButton
                className="ms-2"
                onClick={onSave}
                loading={isSaving}
              >
                Save
              </LoadingButton>
            </Card.Footer>
          </Card>
        )}
      </motion.div>
    </AnimatePresence>
  );
};
DetailSidebar.propTypes = {
  children: PropTypes.node.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  isSaving: PropTypes.bool,
  title: PropTypes.string
};
export default DetailSidebar;
