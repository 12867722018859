import React from 'react';
import UserIcon from 'components/app/users/widgets/UserIcon';
import Flex from './Flex';
import { formatRelative } from 'date-fns';
import { motion } from 'framer-motion';
import { transitions } from 'helpers/animations';
import classNames from 'classnames';
const AuthorStamp = ({
  date,
  userId,
  showIcon,
  className,
  label
}: {
  date?: string | Date;
  userId?: number;
  showIcon?: boolean;
  className?: string;
  label?: string;
}) => {
  return (
    <Flex className={classNames(className, 'flex-column mt-2')}>
      {label && (
        <h6 className="fw-medium text-500 font-base fs--1 mb-0 me-1">
          {label}
        </h6>
      )}
      <motion.div whileHover={'hover'}>
        <Flex alignItems={'center'} justifyContent={'start'} wrap={'wrap'}>
          {userId && (
            <UserIcon
              showIcon={showIcon}
              size="m"
              showName
              hideJobTitle
              nameClass={'text-600 font-base mb-0 me-2'}
              id={userId}
            />
          )}
          {date && (
            <motion.span
              initial={{ opacity: 0, x: -100 }}
              transition={transitions.lightBounce}
              variants={{ hover: { opacity: 1, x: 0 } }}
              className="fw-medium text-500 font-base fs--1"
              style={{ lineHeight: 1.2 }}
            >
              {formatRelative(new Date(date), new Date())}
            </motion.span>
          )}
        </Flex>
      </motion.div>
    </Flex>
  );
};

export default AuthorStamp;
