import { Campaign } from 'apis/flex/recruitment';
import useCampaign from './useCampaign';
import { UseCrudProps } from 'hooks/useDefaultCrud';

export default <T = Partial<Campaign>>({
  id,
  ...rest
}: UseCrudProps<Campaign, T>) => {
  return useCampaign<T>({ id, getPublic: true, ...rest });
};
