import React, { useEffect, useMemo } from 'react';
import { Card, Nav, Tab } from 'react-bootstrap';
import AdvanceTableProvider, {
  AdvanceTableProviderProps,
  useAdvanceTable
} from './AdvanceTableProvider';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import PageSlider from './PageSlider';
const NavItem = ({
  item,
  count,
  onClick
}: {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  item: TabItem<any>;
  count?: number;
}) => {
  return (
    <Nav.Item as="li" className="text-600">
      <Nav.Link
        onClick={onClick}
        style={{
          // backgroundColor:
          //   'rgba(var(--falcon-light-rgb), var(--falcon-bg-opacity)) !important',
          borderColor:
            'var(--falcon-nav-tabs-link-active-border-color) !important'
          // fontFamily: 'var(--falcon-font-sans-serif) !important'
        }}
        className="mb-0 py-3 cursor-pointer h-100 d-inline-flex align-items-center white-space-nowrap w-auto fw-normal border-0 fs--1 fw-semi-bold"
        eventKey={item.label.toLowerCase()}
      >
        {item.icon && (
          <>
            {typeof item.icon === 'function' ? (
              item.icon(count)
            ) : (
              <FontAwesomeIcon icon={item.icon} className="me-1" />
            )}
          </>
        )}
        {item.label} {count ? '(' + count + ')' : ''}
      </Nav.Link>
    </Nav.Item>
  );
};
const Arrow = ({ x, width }: { x: number; width: number }) => {
  return (
    <motion.div
      layout
      className={classNames('position-absolute', { 'd-none': x === null })}
      initial={{ left: 0 }}
      animate={{ left: x }}
      style={{ width: width, bottom: 2 }}
    >
      <div
        className="position-absolute d-block"
        style={{
          position: 'absolute',
          width: 10,
          height: 10,
          bottom: -6,
          left: '45%',
          transform: 'rotate(45deg)',
          background: 'var(--falcon-card-bg)',
          borderRadius: '0.125rem',
          borderWidth: '0 1px 1px 0',
          borderStyle: 'solid',
          borderColor: 'var(--falcon-border-color)'
        }}
      />
    </motion.div>
  );
};
export type TabItem<TData> = {
  icon?: IconProp | ((count: number) => JSX.Element);
  label: string;
  filter: (d: TData) => boolean;
};
const AdvanceTableTabProvider = <TData extends { id?: number } = any>({
  tabs = [],
  data = [],
  children,
  defaultActiveKey,
  cardRef,
  ...rest
}: {
  data: TData[];
  defaultActiveKey?: string;
  tabs: {
    icon?: IconProp | ((count: number) => JSX.Element);
    label: string;
    filter: (d: TData) => boolean;
  }[];
  children: React.ReactNode;
  cardRef?: React.Ref<HTMLDivElement>;
} & Omit<AdvanceTableProviderProps<TData, TData>, 'data' | 'tabs'>) => {
  const [active, setActive] = React.useState<TabItem<TData> | null>(null);
  useEffect(() => {
    if (
      defaultActiveKey &&
      !!tabs?.find(
        t => defaultActiveKey.toLowerCase() === t.label.toLowerCase()
      )
    ) {
      return setActive(
        active ||
          tabs?.find(
            t => defaultActiveKey.toLowerCase() === t.label.toLowerCase()
          )
      );
    }
    if (!defaultActiveKey && !!tabs?.[0]) {
      const activeIndex = tabs.findIndex(
        t => t.label.toLowerCase() === active?.label.toLowerCase()
      );
      console.log('active index', activeIndex);
      if (activeIndex < 1) {
        setActive(tabs?.[0]);
      }
    }
  }, [data, tabs, defaultActiveKey]);
  useEffect(() => {}, [active]);
  const [direction, setDirection] = React.useState<'forward' | 'backward'>(
    'forward'
  );
  const activeData =
    data?.filter(a => {
      // console.log('filtering data', a, active?.filter(a));
      return a && active?.filter(a);
    }) || [];
  // console.log('checking tabs data', active, data, activeData);
  // console.log('table props', rest);
  return (
    <Card ref={cardRef}>
      <Card.Body className="p-0">
        {/* <Row className="g-1"> */}
        {/* {active && ( */}
        <AdvanceTableProvider
          // id={active?.label.toLowerCase()}
          data={activeData}
          animateDirection={direction}
          {...rest}
        >
          <TablePage
            {...{
              active,
              setActive,
              tabs,
              setDirection,
              direction,
              data,
              activeData,
              children
            }}
          />
        </AdvanceTableProvider>
        {/* )} */}
      </Card.Body>
    </Card>
  );
};
const TablePage = ({
  active,
  setActive,
  tabs,
  setDirection,
  direction,
  data,
  activeData,
  children
}) => {
  const motionId = useMemo(() => uniqueId('tab-content'), []);
  const [activeX, setActiveX] = React.useState<number>(null);
  const [activeWidth, setActiveWidth] = React.useState(0);
  const { setPagination } = useAdvanceTable();
  return (
    <Tab.Container
      // key={activeData.length}
      activeKey={active?.label.toLowerCase()}
      // mountOnEnter
      // unmountOnExit
      onSelect={k => {
        if (k) {
          const currentIndex = tabs.findIndex(
            n => n.label.toLowerCase() === active?.label.toLowerCase()
          );
          const newIndex = tabs.findIndex(n => n.label.toLowerCase() === k);
          setActive(tabs[newIndex]);
          setPagination({ pageSize: 10, pageIndex: 0 });
          if (newIndex < currentIndex) {
            setDirection('backward');
          } else {
            setDirection('forward');
          }
        }
      }}
    >
      <div className="pb-2 overflow-x-auto overflow-y-hidden p-0 bg-light">
        {/* <Flex
          justifyContent="between"
          alignItems="center"
          className="ps-0 py-0 border-bottom"
        > */}
        <Nav
          as="ul"
          className="nav-tabs border-0 border-bottom flex-nowrap ps-0 py-0 position-relative"
        >
          {tabs.map(item => (
            <NavItem
              item={item}
              key={item.label.toLowerCase()}
              count={data.filter(a => a && item.filter(a)).length}
              onClick={e => {
                setActiveX(e.currentTarget.offsetLeft);
                setActiveWidth(e.currentTarget.offsetWidth);
              }}
            />
          ))}
          {active && <Arrow x={activeX} width={activeWidth} />}
        </Nav>
        {/* </Flex> */}
      </div>
      <div className="overflow-x-hidden position-relative">
        <PageSlider
          direction={direction}
          motionKey={
            motionId +
            '-' +
            activeData.length +
            '-' +
            active?.label.toLowerCase()
          }
        >
          {children}
        </PageSlider>
      </div>
    </Tab.Container>
  );
};
export default AdvanceTableTabProvider;
