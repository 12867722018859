import { PublicUser } from '../types';
import { UseCrudProps } from 'hooks/useDefaultCrud';
import useUsers from './useUsers';
import { UserView } from 'apis/flex/users';

export default <TSelected = PublicUser[]>(
  props: UseCrudProps<UserView, TSelected> = {}
) => {
  return useUsers<TSelected>({
    ...props,
    getPublic: true,
    staleTime: 1000 * 60 * 10
  });
};
