import FormWizard, {
  FormWizardProps,
  OnWizardScreenout,
  OnWizardSubmit,
  WizardFieldArray
} from 'components/wizard/FormWizard';
import React, { useMemo } from 'react';
import CustomFormSection from './CustomWizardSection';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { Form, FormQuestion, FormSection } from 'apis/flex/customForms';
import { validate } from 'helpers/validation/validate';
import { getRenderIconFromValue } from 'components/wizard/inputs/WizardIcon';

type FieldArrayQuestion = FormQuestion;
type FieldArraySection = FormSection;
type FieldArrayData = {
  sections: FieldArraySection[];
  questions: FieldArrayQuestion[];
};
export const getCustomFormFieldArrayData = (form: Form): FieldArrayData => {
  const sections = useMemo<FieldArraySection[]>(() => {
    const sec = form?.sections || [];
    if (form?.intro) {
      return [
        {
          icon: faInfo,
          name: 'Introduction',
          questions: [{ inputType: 'info', questionText: form.intro }]
        },
        ...sec
      ];
    }
    return sec;
  }, [form]);
  const questions = useMemo<FieldArrayQuestion[]>(
    () => sections.reduce((a, b) => a.concat(b.questions || []), []),
    [sections]
  );
  return { sections, questions };
};
export const getCustomFormFieldArray = (
  form: Form,
  onScreenout?: OnWizardScreenout
): WizardFieldArray<FieldArraySection> => {
  const { sections, questions } = getCustomFormFieldArrayData(form);
  return {
    data: sections,
    icon: section => getRenderIconFromValue(section.icon),
    label: section => section.name,
    output: section => (
      <CustomFormSection allQuestions={questions} section={section} />
    ),
    delayNext: section => section.delayNext,
    onSubmit: (section, vals, done, fail, screenout) => {
      const failQuestion = section.questions
        ?.filter(q => q.screening)
        .filter(q => {
          const screenoutReason = validate.trueIfAllPass(
            q.screening,
            () => q.inputType,
            () => vals[q.id.toString()]
          );
          return typeof screenoutReason === 'string';
        });
      if (failQuestion?.length) {
        screenout(failQuestion.map(q => q.id));
        //if onScreenout is specified, it leaves that function to deal with how the form should continue
        if (onScreenout) return;
      }
      done();
    }
  };
};
export type CustomFormWizardProps = Omit<FormWizardProps, 'onSubmit'> & {
  form: Form;
  view?: boolean;
  onSubmit?: OnWizardSubmit;
  pagesBefore?: () => React.ReactNode;
  pagesAfter?: () => React.ReactNode;
};
/**
 * Responsible only for converting the custom form data into a wizard
 */
const CustomFormWizard = ({
  form,
  view = false,
  authItemCollection = 'form-response',
  continueOnScreenout = false,
  onScreenout = null,
  onSubmit = ({ done }) => done(),
  pagesBefore,
  pagesAfter,
  ...rest
}: CustomFormWizardProps) => {
  return (
    <FormWizard
      readOnly={view}
      success={{ title: form?.outro }}
      authItemCollection={authItemCollection}
      allowPrev={form?.allowPrev || process.env.NODE_ENV === 'development'}
      onSubmit={onSubmit}
      onScreenout={onScreenout}
      {...rest}
      continueOnScreenout={continueOnScreenout}
      fieldArray={{
        ...getCustomFormFieldArray(form, onScreenout),
        pagesAfter,
        pagesBefore
      }}
    />
  );
};

export default CustomFormWizard;
