import { GroupByType } from 'components/app/reporting/helpers';
import api from '.';
import CrudApi from './CrudApi';
import { CustomRule } from 'helpers/validation/validate';
import { RoleDomain } from './users';
export type Aggregator = 'sum' | 'count' | 'avg';

export type ReportConfig<TData = any> = {
  type: string;
  id: number;
  title?: string;
  description?: string;
  db: string;
  table: string;
  xAxes: { field: keyof TData & string; groupBy?: GroupByType }[];
  seriesSplitBy?: keyof TData & string;
  seriesGroupBy?: GroupByType;
  series?: {
    name: string;
    axisIndex?: number;
    filters: CustomRule;
    aggregation?: Aggregator;
    aggregationField?: string;
  }[];
  aggregation?: Aggregator;
  aggregationField?: string;
  filters?: CustomRule;
  createdDate?: Date;
  createdBy?: number;
  companyId?: number;
  compareTo?: {
    db: string;
    table: string;
    filters: CustomRule;
    xAxes: { field: string }[];
    calculation: 'Difference' | 'Percentage';
    aggregation?: Aggregator;
    aggregationField?: string;
  };
  realTime?: boolean;
};
export type PageReport = {
  id: number;
  reportId: number;
  location?: string;
  index: number;
  createdBy?: number;
  createdDate?: Date;
};
export type ReportDataRow<TAxis extends string = string> = {
  count: number;
  _comparisonValue?: number;
} & Record<TAxis, any>;
export type ReportData<
  TSeries extends string = string,
  TAxis extends string = string
> = Record<TSeries, ReportDataRow<TAxis>[][]>;
export const reportConfigsApi = new CrudApi<ReportConfig>(
  '/helpers/reports/configs'
);
export const pageReportsApi = new CrudApi<PageReport>('/helpers/reports/page');
export const getReportData = <
  TSeries extends string = string,
  TAxis extends string = string
>({
  id,
  config
}: {
  id?: number;
  config?: Partial<ReportConfig>;
}) =>
  api
    .get<ReportData<TSeries, TAxis>>('/helpers/reports/data', {
      params: id ? { id } : config
    })
    .then(d => d.data);
export type ApiDomainConfig = Record<RoleDomain, { access: string[] }>;
export const getDomainConfigs = () => {
  return api
    .get<ApiDomainConfig>('/helpers/reports/domain-config')
    .then(d => d.data);
};
