import PageLoader from 'components/common/PageLoader';
import useUrlSearchParams from 'hooks/useUrlSearchParams';
import React, { useEffect } from 'react';

export default () => {
  const u = useUrlSearchParams();
  useEffect(() => {
    if (!u?.code) return;
    window.parent.postMessage({
      data: { accessCode: u.code },
      source: 'oneid-redirect'
    });
  }, [u?.code]);
  useEffect(() => {
    if (!u?.error) return;
    window.parent.postMessage({
      source: 'oneid-redirect',
      error: new Error(
        u.error ? u.error + ': ' + u.error_oneid : 'Unknown error'
      )
    });
  }, [u?.error]);
  return <PageLoader />;
};
