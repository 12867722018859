import { useEffect } from 'react';
import { unstable_useBlocker, useNavigate } from 'react-router-dom';

export default (
  when = true,
  afterPrompt: (confirmed?: boolean) => void = () => {}
) => {
  const navigate = useNavigate();
  // const handleNavAttempt = (
  //   location,
  //   options = null,
  //   proceed = navigate,
  //   reset = () => {}
  // ) => {
  //   const doNavigate = () => {
  //     if (typeof location === 'number') {
  //       return proceed(location);
  //     } else {
  //       return proceed(location, options);
  //     }
  //   };
  //   if (when) {
  //     return doNavigate();
  //   }
  //   if (
  //     confirm(
  //       'You may have unsaved data on this page. Are you sure you want to leave?'
  //     )
  //   ) {
  //     afterPrompt(true);
  //     return doNavigate();
  //   }
  //   reset();
  //   afterPrompt(false);
  // };
  // function nav(location: number): void;
  // function nav(location: To | number, options?: NavigateOptions): void {
  //   console.log('should nav?', when);
  //   handleNavAttempt(location, options);
  // }
  const navBlocker = unstable_useBlocker(!when);
  // console.log('navblocker', navBlocker, when);
  useEffect(() => {
    // console.log('should block?', navBlocker.state === 'blocked');
    if (navBlocker.state === 'blocked') {
      if (
        confirm(
          'You may have unsaved data on this page. Are you sure you want to leave?'
        )
      ) {
        // console.log('ignoring block');
        afterPrompt(true);
        return navBlocker.proceed();
      }
      // console.log('resetting block');
      navBlocker.reset();
      afterPrompt(false);
    }
  }, [navBlocker, when]);
  useEffect(() => {
    // console.log('should reset?', navBlocker.state === 'blocked', !when);
    if (navBlocker.state === 'blocked' && when) {
      navBlocker.reset();
    }
  }, [navBlocker, when]);
  useEffect(() => {
    // console.log('checking when. Should allow?', !when);
    if (!when) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
    return () => (window.onbeforeunload = undefined);
  }, [when]);
  return navigate;
};
