import WizardInput from 'components/wizard/WizardInput';
import React, { useEffect } from 'react';
import SelectedApplicants from './widgets/SelectedApplicants';
import StageForm from '../stages/StageForm';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';
import useCampaignApplicants from './hooks/useCampaignApplicants';
import useStages from './hooks/useStages';
const MoveToStage = ({
  campaignId,
  ids = [],
  namePrefix = ''
}: {
  campaignId: number;
  ids?: number[];
  namePrefix?: '' | `${string}.`;
}) => {
  const selectedStage = useWatch({ name: namePrefix + 'stageId' });
  const { setValue } = useFormContext();
  useEffect(() => {
    if (selectedStage) {
      const { sendEmail, notificationTemplateId } = data.find(
        s => s.value == selectedStage
      );
      setValue('sendEmail', sendEmail);
      setValue('notificationTemplateId', notificationTemplateId);
    }
  }, [selectedStage]);
  const { data: applicants = [], isLoading: applicantsLoading } =
    useCampaignApplicants({
      campaignId,
      select: d => d.filter(d => ids.some(a => a == d.applicant.id))
    });
  const { data, isLoading } = useStages({
    campaignId,
    enabled: !applicantsLoading,
    select: d =>
      d.map(s => ({
        label: s.name,
        value: s.id,
        disabled: applicants.every(
          a => a.applicant.currentStage?.stageId == s.id
        ),
        ...s
      }))
  });
  return (
    <>
      <SelectedApplicants ids={ids} />
      <WizardInput
        name={namePrefix + 'stageId'}
        label="Stage"
        loading={isLoading}
        type="select"
        options={data}
      />
      <StageForm
        withDueDate={!!data?.find(s => s.value == selectedStage)?.formId}
      />
    </>
  );
};
export default MoveToStage;
