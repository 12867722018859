import React, { ReactNode } from 'react';
import { Card, OverlayTrigger } from 'react-bootstrap';
import FalconCardHeader from './FalconCardHeader';
import { OverlayTriggerType } from 'react-bootstrap/esm/OverlayTrigger';

export default ({
  title,
  body,
  children,
  trigger = ['click'],
  cardProps,
  onClick,
  allowClick
}: {
  trigger?: OverlayTriggerType | OverlayTriggerType[];
  title?: ReactNode;
  body: ReactNode;
  children: ReactNode;
  cardProps?: any;
  onClick?: () => void;
  allowClick?: boolean;
}) => {
  return (
    <div
      onClick={e => !allowClick && (onClick ? onClick() : e.stopPropagation())}
    >
      <OverlayTrigger
        trigger={trigger}
        rootClose
        placement="auto"
        overlay={
          <Card
            {...cardProps}
            style={{ maxWidth: '600px', zIndex: 1023, ...cardProps?.style }}
          >
            {title && (
              <FalconCardHeader
                title={<div>{title}</div>}
                className="d-flex justify-content-between"
              />
            )}
            <Card.Body>{body}</Card.Body>
          </Card>
        }
      >
        <div className="position-relative">{children}</div>
      </OverlayTrigger>
    </div>
  );
};
