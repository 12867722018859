import { customFormsResponseApi, FormResponse } from 'apis/flex/customForms';
import useDefaultCrud, { UseCrudProps } from 'hooks/useDefaultCrud';
import { useMemo } from 'react';

export default <TSelected = FormResponse>({
  responseId,
  select = d => d as TSelected,
  ...rest
}: {
  responseId?: number;
} & UseCrudProps<FormResponse, TSelected>) => {
  const enabled = useMemo(() => !!responseId, [responseId]);
  return useDefaultCrud<FormResponse, TSelected>(
    'CustomFormResponses',
    customFormsResponseApi,
    {
      enabled,
      id: responseId,
      select: d => select(d)[0],
      ...rest
    }
  );
};
