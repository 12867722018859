import useShortLinkClicks from 'hooks/useShortLinkClicks';
import React from 'react';
import CampaignChart from './CampaignChart';
import { Card, CardProps } from 'react-bootstrap';
import Flex from '../Flex';
import DiffBadge from './DiffBadge';

export default ({ linkId, ...rest }: { linkId: number } & CardProps) => {
  const { counts } = useShortLinkClicks({
    filters: { shortLinkId: linkId },
    useFilter: !!linkId,
    countBy: ['clickDate']
  });
  const total = counts?.reduce((a, b) => a + b.count, 0);
  const mostRecentDay = counts?.[counts.length - 1]?.count || 0;
  const secondMostRecentDay = counts?.[counts.length - 2]?.count || 0;
  return (
    <Card className="h-md-100" {...rest}>
      <Card.Header className="pb-0">
        <h6 className="mb-0 mt-2">Clicks</h6>
      </Card.Header>

      <Card.Body
        as={Flex}
        alignItems="end"
        justifyContent="between"
        className="pt-0"
      >
        <div>
          <h2 className="fw-normal text-700 mb-1 lh-1">{total}</h2>
          <DiffBadge current={mostRecentDay} prev={secondMostRecentDay} />
        </div>
        <div className="ps-0">
          <CampaignChart data={counts?.map(x => x.count)} />
        </div>
      </Card.Body>
    </Card>
  );
};
