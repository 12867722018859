import { ApiFilters } from 'apis/types';
import api from './index';
import CrudApi from './CrudApi';

export type EventPrefix =
  | 'applicant'
  | 'applicant-stage'
  | 'user'
  | 'user-roles'
  | 'user-subscription'
  | 'form'
  | 'form-response'
  | 'ticket'
  | 'notification'
  | 'academy'
  | 'email'
  | 'socket-notification'
  | 'recruitment-campaign'
  | 'contract'
  | 'new-starter'
  | 'user-group'
  | 'training-course'
  | 'user-training'
  | 'employee-screening'
  | 'employee'
  | 'analysis'
  | 'recruitment-pipeline'
  | 'department'
  | 'mailer-template'
  | 'app-notification'
  | 'short-link'
  | 'report-config'
  | 'file-upload'
  | 'page-report'
  | 'file'
  | 'transaction'
  | 'mailer-preference'
  | 'client'
  | 'project'
  | 'quote'
  | 'target-type'
  | 'target-group'
  | 'team-role'
  | 'project-role';
export type EventAction =
  | 'ready'
  | 'requested'
  | 'added'
  /**
   * 'updated' event is emitted with the full data.
   */
  | 'updated'
  | 'removed'
  | 'sent'
  | 'submitted'
  | 'error'
  | 'accepted'
  | 'rejected'
  | 'loggedin'
  /**
   * 'changed' event is emitted with a diff of the data that has changed.
   * 'updated' event is emitted with the full data.
   */
  | 'changed';
export type EventName = `${EventPrefix}-${EventAction}`;
export type NotificationMessages = {
  title: string;
  body?: string[];
  subject?: string;
  actionButton?: {
    label: string;
    href: string;
  };
};
export type Notification = {
  id?: number;
  userId: number;
  eventName: EventName;
  itemId?: number;
  sent?: Date;
  type: string;
  domain: EventPrefix;
  byEmail: boolean;
  byApp: boolean;
  pipelineId: string;
  authorId: number;
  authorName: string;
  messages: NotificationMessages;
  seen?: Date;
  actioned?: Date;
  emailStatus?: string;
  emailDetailedStatus?: string;
};
export type NotificationRolledUp = Notification & {
  count: number;
  itemIds: number[];
  authorIds: number[];
  ids: number[];
};
export type AppNotification = {
  id: number;
  eventName: EventName;
  name: string;
  description: string;
  domain: EventPrefix;
  templates: MailerTemplate[];
};
export const getAppNotifications = () =>
  api.get<AppNotification[]>('/mailer/notifications/app').then(d => d.data);
export const getNotificationsSubscriptions = () =>
  api.get('/mailer/notifications/subscriptions/').then(d => d.data);
export const updateNotificationsSubscriptions = subscriptions =>
  api
    .patch('/mailer/notifications/subscriptions/', { data: subscriptions })
    .then(d => d.data);
export const getUserNotifications = (
  page: number,
  id?: number,
  filters?: ApiFilters
) =>
  api
    .get<NotificationRolledUp[]>('/mailer/notifications/' + (id || ''), {
      params: { page, filters }
    })
    .then(d => d.data);
export const getAllUserNotifications = (filters?: ApiFilters) =>
  api
    .get<Notification[]>('/mailer/notifications/', {
      params: { filters }
    })
    .then(d => d.data);
export type NotificationCount = Record<string, number>;
export const getUserNotificationCounts = () =>
  api.get<NotificationCount>('/mailer/notifications/count').then(d => d.data);
export const markNotificationAsSeen = ({ id, eventName, domain, itemId }) =>
  api.patch('/mailer/notifications/seen', { id, eventName, domain, itemId });
export const markNotificationAsActioned = ({ id, eventName, domain, itemId }) =>
  api.patch('/mailer/notifications/actioned', {
    id,
    eventName,
    domain,
    itemId
  });
export const messageUsers = (ids, message) =>
  api.post('/mailer/notify', { ids, message });
export const resendEmail = ({ userId, eventName }) =>
  api.post('/mailer/notifications/resend', { userId, eventName });
export type MailerObject = {
  subject: string;
  intro: string;
  body: string;
};
export type MailerTemplate = MailerObject & {
  id: number;
  name: string;
  notificationId: number;
  companyId: number;
  notificationType: string;
  modifiedBy: number;
  modifiedDate: Date;
  notificationName: string;
  eventName: string;
  isDefault?: boolean;
};
export const mailerTemplatesApi = new CrudApi<MailerTemplate>(
  '/mailer/templates'
);
export const getTemplatePreview = (
  data: MailerTemplate,
  domain: EventPrefix,
  action: EventAction
) =>
  api
    .post(
      '/mailer/templates/preview/' + domain + (action ? '/' + action : ''),
      { data }
    )
    .then(d => d.data);
export const getFakeTemplateData = () =>
  api
    .get<Record<EventPrefix, any>>('/mailer/templates/fake-data')
    .then(d => d.data);
export const appNotificationsApi = new CrudApi<AppNotification>(
  '/mailer/notifications/app'
);
export type MailerPreference = {
  id: number;
  email?: string;
  telephone?: string;
  addedDate?: Date;
  notificationId?: number;
  type: 'blacklist';
};
export const mailerPreferencesApi = new CrudApi<MailerPreference>(
  '/mailer/preferences'
);
