import { useQuery } from '@tanstack/react-query';
import {
  getAllUserNotifications,
  getUserNotifications
} from 'apis/flex/notifications';
import { ApiFilters } from 'apis/types';

export default ({
  id,
  filters,
  enabled = true,
  ...rest
}: {
  id?: number;
  filters?: ApiFilters;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: ['Notifications', id, filters],
    queryFn: () => getUserNotifications(1, id, filters),
    enabled,
    ...rest
  });
};
export const useNewDomainNotifications = ({
  domain,
  ...rest
}: {
  domain?: string;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: ['NewNotifications', domain],
    queryFn: () => getAllUserNotifications({ domain, actioned: 'null' }),
    staleTime: 1000 * 20,
    ...rest
  });
};
