import React from 'react';
import WizardInput from 'components/wizard/WizardInput';
import { useWatch } from 'react-hook-form';
import ContractPicker from './ContractPicker';

const UserContractForm = ({ name = '' }: { name?: '' | `${string}.` }) => {
  const id = useWatch({ name: name + 'contract.id' });
  return (
    <>
      <ContractPicker
        name={name + 'contract.id'}
        registerProps={{ required: false }}
        filter={d => d.isActive}
      />
      {id && (
        <>
          <WizardInput
            type="date"
            label="Contract start"
            name={name + 'contract.startDate'}
          />
          <WizardInput
            type="date"
            label="Contract end"
            name={name + 'contract.endDate'}
            registerProps={{
              required: false,
              validate: (v, d) =>
                !v ||
                new Date(v) > new Date(d.contract.startDate) ||
                'Must be after start date'
            }}
          />
        </>
      )}
    </>
  );
};
export default UserContractForm;
