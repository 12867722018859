import WizardInput, { WizardInputProps } from 'components/wizard/WizardInput';
import React from 'react';
import useAppNotifications from 'components/notification/useAppNotifications';
import {
  EventAction,
  EventPrefix,
  MailerTemplate
} from 'apis/flex/notifications';
import CustomTooltip from 'components/common/Tooltip';
import { EmailPreview } from './TemplateEditor';
import { FormatOptionLabelMeta } from 'react-select';
import Flex from 'components/common/Flex';
import { Button } from 'react-bootstrap';
import CustomPopover from 'components/common/CustomPopover';
import { FormProvider, useForm } from 'react-hook-form';
const LabelWithHoverPreview = ({
  template,
  label,
  domain,
  action
}: {
  label: string;
  template: MailerTemplate;
  domain: EventPrefix;
  action: EventAction;
}) => {
  //has to be in its own form so it doesn't interfere with outer form
  const methods = useForm();
  return (
    <CustomPopover
      trigger={['hover', 'focus']}
      allowClick
      cardProps={{
        style: { width: 500 }
      }}
      body={
        <FormProvider {...methods}>
          <EmailPreview
            height={500}
            domain={domain}
            action={action}
            template={template}
          />
        </FormProvider>
      }
    >
      <div className="d-block w-100 h-100">{label}</div>
    </CustomPopover>
  );
};
export default ({
  domain,
  action,
  ...rest
}: {
  domain?: EventPrefix;
  action?: EventAction;
} & Partial<WizardInputProps>) => {
  const { data } = useAppNotifications({
    filters: { eventName: `${domain}-${action}` },
    useFilter: !!(domain && action),
    staleTime: 1000 * 20
  });
  return (
    <WizardInput
      type="select"
      pluginProps={{
        formatOptionLabel: (data: any) => {
          return (
            <LabelWithHoverPreview
              label={data.label}
              template={data.context}
              domain={domain}
              action={action}
            />
          );
        }
      }}
      options={data?.[0]?.templates.map(t => ({
        label: t.name,
        value: t.id,
        description: t.isDefault ? 'Default' : '',
        context: t
        //TODO: add hover that shows rendered email body
      }))}
      registerProps={{ required: false }}
      instruction="The default will be used if none is selected"
      name="notificationTemplateId"
      label={
        <Flex alignItems={'baseline'}>
          <span>Email template</span>
          <a
            href={`/settings/notifications/templates/new?notificationId=${data?.[0]?.id}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button size="sm" variant="link" className="p-0 ms-2">
              Add new
            </Button>
          </a>
        </Flex>
      }
      {...rest}
    />
  );
};
