import { useMutation } from '@tanstack/react-query';
import AdvanceTable from 'components/common/advance-table-v2/AdvanceTable';
import AdvanceTableProvider, {
  AdvanceTableProviderProps,
  Column,
  ColumnObject
} from 'components/common/advance-table-v2/AdvanceTableProvider';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import CustomWizardInput from '../Wizard/CustomWizardInput';
import { Button, Modal, ProgressBar } from 'react-bootstrap';
import CustomFormViewer from './CustomFormViewer';
import useFormQuestions from '../hooks.js/useFormQuestions';
import useFormResponses from '../hooks.js/useFormResponses';
import WizardInput from 'components/wizard/WizardInput';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch
} from 'react-hook-form';
import { csv } from 'd3';
import classNames from 'classnames';
import { truncate, uniqBy } from 'lodash';
// import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { getCustomInputWrap } from '../helpers';
import { ResponsiveModal } from 'components/common/Modals';
import { FormQuestion, FormResponse } from 'apis/flex/customForms';
import AdvanceTableTabProvider, {
  TabItem
} from 'components/common/advance-table-v2/AdvanceTableTabProvider';
const ResponseModal = ({ show, setShow }) => {
  return (
    <ResponsiveModal wide size="lg" show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton />
      <CustomFormViewer responseId={show?.id} formId={show?.formId} />
    </ResponsiveModal>
  );
};
ResponseModal.propTypes = {
  show: PropTypes.any,
  setShow: PropTypes.func
};
const Validator = ({ question, setErrors, data, csvField, onComplete }) => {
  const methods = useForm();
  const [index, setIndex] = useState(0);
  useEffect(() => {
    if (csvField) {
      setIndex(0);
    }
  }, [data, csvField]);
  useEffect(() => {
    if (csvField) {
      methods.setValue(question.qid, data[index]);
    }
  }, [index, csvField]);
  const inputValue =
    methods.watch(question.qid) || methods.getValues(question.qid);
  useEffect(() => {
    if (csvField) {
      methods.handleSubmit(
        () => {
          if (index < data.length - 1) {
            setIndex(index + 1);
          } else {
            setErrors({});
            onComplete();
          }
        },
        errors => {
          console.log('validation errors', errors);
          setErrors({ ...errors[question.qid], row: index, value: inputValue });
          onComplete();
        }
      )();
    }
  }, [inputValue, index]);
  return (
    <FormProvider {...methods}>
      <div className="d-none">
        <CustomWizardInput question={{ ...question }} />
      </div>
      <ProgressBar
        min={0}
        max={data.length}
        now={index}
        // animated={true}
        striped={true}
        // label={'Validating'}
      />
    </FormProvider>
  );
};
Validator.propTypes = {
  question: PropTypes.object,
  setErrors: PropTypes.func,
  data: PropTypes.array,
  csvField: PropTypes.string,
  onComplete: PropTypes.func
};
const ImporterField = ({ question, index, data, headers }) => {
  const [errors, setErrors] = useState<{
    row?: number;
    message?: string;
    value?: string;
  }>();
  const [validating, setValidating] = useState<boolean>();
  const { trigger, getValues } = useFormContext();
  const value =
    useWatch({ name: 'questions.' + index + '.csvField' }) ||
    getValues('questions.' + index + '.csvField');
  const [csvField, setCsvField] = useState();
  useEffect(() => {
    if (value) {
      trigger('questions.' + index + '.csvField');
    }
  }, [errors]);
  const dataValues = useMemo(
    () => csvField && data.map(d => d[csvField]),
    [data, csvField]
  );
  useEffect(() => {
    if (value !== csvField) {
      setValidating(true);
      setCsvField(value);
    }
  }, [value]);
  return (
    <div className="mb-4">
      <WizardInput
        name={'questions.' + index + '.csvField'}
        label={
          question.label +
          '. ' +
          truncate(question.questionText, { length: 150 })
        }
        type="select"
        options={headers}
        formGroupProps={{
          className: classNames({ 'd-none': !!validating && value })
        }}
        registerProps={{
          validate: () => {
            const message =
              'Error on row ' +
              errors?.row +
              ': ' +
              errors?.message +
              " (value was '" +
              errors?.value +
              "')";
            return !errors || Object.keys(errors).length === 0 || message;
          }
        }}
        instruction={'Must be in a vaid ' + question.inputType + ' format'}
      />
      {csvField && validating && (
        <div className="mb-3" style={{ minHeight: 38 }}>
          <Validator
            csvField={csvField}
            question={question}
            data={dataValues}
            setErrors={setErrors}
            onComplete={() => setValidating(false)}
          />
        </div>
      )}
    </div>
  );
};
ImporterField.propTypes = {
  question: PropTypes.object,
  index: PropTypes.number,
  data: PropTypes.object,
  headers: PropTypes.array
};
const ImportModal = ({ show, setShow }) => {
  const formId = show;
  const methods = useForm<{
    dataFile: { blob: any }[];
    questions: FormQuestion & { qid?: string }[];
  }>({ mode: 'onChange', defaultValues: { dataFile: [], questions: [] } });
  const { fields, replace } = useFieldArray({
    control: methods.control,
    name: 'questions'
  });
  const dataFile = methods.watch('dataFile');
  const [headers, setHeaders] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const { mutate: parseCsv } = useMutation({ mutationFn: data => csv(data) });
  useEffect(() => {
    // methods.setValue('questions', []);
    setHeaders([]);
    setCsvData([]);
    if (dataFile && dataFile[0]) {
      parseCsv(dataFile[0].blob, {
        onSuccess: (data: any) => {
          setHeaders(data.columns);
          delete data.columns;
          setCsvData(data);
        }
      });
    }
  }, [dataFile]);
  const { data: questions } = useFormQuestions({ formId });
  useEffect(() => {
    if (questions) {
      replace(questions.map(q => ({ ...q, qid: q.id.toString() })));
    }
  }, [questions]);
  const handleSubmit = methods.handleSubmit(vals => {
    console.log(vals);
  });
  return (
    <ResponsiveModal size="lg" show={!!show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Import Responses</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormProvider {...methods}>
          <WizardInput
            name="dataFile"
            hideLabel
            pluginProps={{
              accept: { 'text/csv': ['.csv'] },
              maxFiles: 1,
              label: 'Upload a .csv file'
            }}
            type="file"
          />
          {fields &&
            fields.map((field, i) => (
              <ImporterField
                key={field.id}
                question={{ ...field, id: field.qid }}
                index={i}
                data={csvData}
                headers={headers}
              />
            ))}
        </FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={() => setShow(false)}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Import</Button>
      </Modal.Footer>
    </ResponsiveModal>
  );
};
ImportModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func
};
const getType = (q: FormQuestion) => {
  switch (q.inputType) {
    case 'interview':
    case 'transcribe':
    case 'speaking':
      return 'score';
    default:
      return q.inputType;
  }
};
export type CustomFormResponseTableData = FormResponse & {
  metadata?: any;
} & Record<any, any>;
const CustomFormResponseTable = ({
  formId,
  allQuestions = false,
  tableProps = {},
  children = null,
  isLoading = false,
  emptyPlaceholder = null,
  columns = null,
  error = null,
  tabs,
  ...rest
}: {
  formId: number;
  allQuestions?: boolean;
  tableProps?: any;
  emptyPlaceholder?: ReactNode;
  children?: ReactNode;
  tabs?: TabItem<any>[];
} & Partial<
  AdvanceTableProviderProps<FormResponse, CustomFormResponseTableData>
>) => {
  const {
    data: questions,
    isLoading: questionsLoading,
    error: questionsError
  } = useFormQuestions<ColumnObject<FormResponse & { metadata?: any }>[]>({
    formId,
    staleTime: 1000 * 30,
    select: questions =>
      questions.map(q => ({
        id: q.id.toString(),
        header: q.label,
        type: getType(q),
        editor: props => <CustomWizardInput question={q} inputProps={props} />,
        wrap: getCustomInputWrap(q.inputType),
        visible: !!allQuestions,
        headerDownload: q.label,
        options: q.options
          ?.filter(o => o !== '')
          .map(o => ({
            value: o,
            label: o
          })),
        accessorFn: d => {
          const response = d[q.id.toString()];
          return d.metadata?.[q.id.toString()]?.analysis || response;
        }
        // formatter: getValue =>
        //   customInputValueFormatter(q.inputType)(getValue())
      }))
  });
  const defaultCols: Column<FormResponse>[] = [
    { id: 'id', visible: false },
    { id: 'submittedDate', type: `datetime` }
  ];
  const allColumns = uniqBy<Column<FormResponse>>(
    (columns || [])
      .map<Column<FormResponse>>(col => {
        const id = typeof col === 'string' ? col : col.id;
        return {
          id,
          ...(typeof col === 'string' ? {} : col),
          ...questions?.find(q => q.id == id)
        };
      })
      .concat(defaultCols)
      .concat(questions || []),
    'id'
  );
  const [responseModal, setResponseModal] = useState<{
    id: number;
    formId: number;
  }>();
  const [importModal, setImportModal] = useState();
  const {
    data,
    isLoading: dataLoading,
    error: dataError
  } = useFormResponses({
    formId,
    staleTime: 1000 * 30,
    select: d => {
      const metaLookup = d?.reduce((a, b) => {
        a[b.id] = b.data.raw?.reduce(
          (aa, bb) => ({
            ...aa,
            [bb.questionId]: bb.metadata
          }),
          {}
        );
        return a;
      }, {});
      return d.map(r => ({
        ...r,
        ...r.data.form,
        metadata: metaLookup[r.id]
      }));
    }
  });
  const Component = tabs ? AdvanceTableTabProvider : AdvanceTableProvider;
  return (
    <Component
      tabs={tabs}
      columns={allColumns}
      error={error || dataError || questionsError}
      // tableActions={[
      //   {
      //     name: 'Import',
      //     icon: faFileImport,
      //     onClick: () => setImportModal(formId)
      //   }
      // ]}
      dataAccessor={r => {
        return {
          ...r,
          original: { ...r.original, ...r.original?.data?.labelled }
        };
      }}
      data={questions ? data : []}
      isLoading={isLoading || questionsLoading || dataLoading}
      onRowClick={r => setResponseModal({ id: r.original.id, formId })}
      {...rest}
    >
      <AdvanceTable
        // isLoading={isLoading || questionsLoading || dataLoading}
        {...tableProps}
        emptyPlaceholder={emptyPlaceholder}
      />
      {children}
      <ResponseModal show={responseModal} setShow={setResponseModal} />
      <ImportModal show={importModal} setShow={setImportModal} />
    </Component>
  );
};
export default CustomFormResponseTable;
